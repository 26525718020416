import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardFavouritesAnnouncement from './CardFavouritesAnnouncement';
import { getUserOutletFunc } from '../states/outletStore';
import { getFavouritesAnnouncementsByIdFunc } from '../states/storeState';
import { setIsLogged } from '../states/loginState';
import { useNavigate } from 'react-router';
import decodeToken from '../functions/decodeToken';
import CardPenRejAnnouncementGrid from './CardPenRejAnnouncementGrid';
import CardPenRejAnnouncementLine from './CardPenRejAnnouncementLine';
import { nanoid } from 'nanoid';

const _Favourites = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const favouritesData = useSelector((state) => state.myStore.favouritesData);
    const scrollRef = useRef();

    const locStorView = localStorage.getItem("typeOfView");
    const [typeOfView, setTypeOfView] = useState(locStorView ? +locStorView : 2);
    const tkn = decodeToken();
    const token = localStorage.getItem("token");


    useEffect(() => {

        if (token) {
            dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
                .then((res) => {
                    if (res.payload.statusCode !== 408 && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {
                        dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token: token }));
                    }
                })
        } else {
            dispatch(setIsLogged(false));
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);


    return (

        <div>
            <div className='d-flex justify-content-around align-items-center pt-3 mb-4 text-light'  ref={scrollRef}>
                <div>
                    <div className='d-flex justify-content-center align-items-center gap-2' style={{ fontSize: "1.5rem" }}>
                        <div className={`myCursor border border-dark myBgDark p-2 py-1 ${typeOfView === 2 ? "bg-light text-dark" : ""} rounded-1`} onClick={() => { setTypeOfView(2); localStorage.setItem("typeOfView", "2") }}><i className="bi bi-columns-gap myCursor"></i></div>
                        <div className={`myCursor border border-dark myBgDark p-2 py-1 ${typeOfView === 0 ? "bg-light text-dark" : ""} rounded-1`} onClick={() => { setTypeOfView(0); localStorage.setItem("typeOfView", "0") }}><i className="bi bi-grid myCursor"></i></div>
                        <div className={`myCursor border border-dark myBgDark p-2 py-1 ${typeOfView === 1 ? "bg-light text-dark" : ""} rounded-1`} onClick={() => { setTypeOfView(1); localStorage.setItem("typeOfView", "1") }}><i className="bi bi-list-task myCursor"></i></div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center mt-1'>
                        <div>{favouritesData.length} favourites</div>
                    </div>
                </div>
            </div>
            {
                favouritesData && favouritesData.length !== 0 ?
                    <div>
                        {
                            typeOfView === 2 ?
                                <div className='pt-3 pb-5 mb-5 w-100 h-100 verticalGrid'>
                                    {
                                        favouritesData && favouritesData.map((el) => {
                                            return <CardPenRejAnnouncementGrid key={nanoid()} idOwn={tkn.id} singleData={el} isLoading={isLoading} />
                                        })
                                    }

                                </div>
                                :
                                <div className='d-flex flex-wrap justify-content-center align-items-center mb-5 mt-3 pb-5 px-1'>
                                    {
                                        favouritesData && favouritesData.map((el) => {
                                            if (typeOfView === 0) {
                                                return <CardFavouritesAnnouncement key={nanoid()} idOwn={tkn.id} singleData={el} isLoading={isLoading} superuser={false} />
                                            } if (typeOfView === 1) {
                                                return <CardPenRejAnnouncementLine key={nanoid()} idOwn={tkn.id} singleData={el} isLoading={isLoading} />
                                            }
                                        })
                                    }
                                </div>
                        }
                    </div>
                    :
                    !isLoading && <h1 className='mt-5 fw-light text-secondary text-center'>No favourites</h1>

            }
        </div >

    )
}

export default _Favourites