import { React, useEffect, useState, useRef } from 'react'
import { postCreateAnnouncementFunc } from '../states/storeState';
import { saveAnnouncementPayload } from '../states/storeState';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Resizer from "react-image-file-resizer";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import productCategories from '../assets/JSON/productCategories.json';
import { setCategoriesProduct, delCategoriesProduct } from '../states/generalState';
import countriesArray from '../assets/JSON/countriesIso2Arry.json';
import { setSellingAreaExcluded, delSellingAreaExcluded, clearSellingAreaExcluded, setSellingAreaSelected, delSellingAreaSelected, clearSellingAreaSelected } from '../states/generalState';
import SlotCardAnn from './SlotCardAnn';
import slotPrices from '../assets/JSON/slotPrices.json';
import commonCurrencyJSON from '../assets/JSON/commonCurrency.json';
import decodeToken from '../functions/decodeToken';
import { getSingleUserFunc } from '../states/loginState';
import { generateScore, clearScore } from '../states/annScoreState';

function _FormAnnouncement() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [file, setFile] = useState("");
  const [idOwner, setIdOwner] = useState(null);
  const [slotCode, setSlotCode] = useState(0);
  const [brandName, setBrandName] = useState("");
  const [manufacturerName, setManufacturerName] = useState("");
  const [modelName, setModelName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [productSize, setProductSize] = useState("");
  const [description, setDescription] = useState("");
  const [techDetail, setTechDetail] = useState([]);
  const [techDetailView, setTechDetailView] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [zoomIn, setZoomIn] = useState({ field: "", value: false });
  const [step, setStep] = useState(0);
  const [totalPrice, setTotalPrice] = useState("0");
  const [errorMessage, setErrorMessage] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isFirstTry, setIsFirstTry] = useState(true);
  const [techDetailValueError, setTechDetailValueError] = useState(false);
  const [score, setScore] = useState(0);
  const isCurrencyNeeded = () => { if (price > 0) { return currency } else { return true } };
  const checkQuantity = (e) => { console.log(price); if (+e.target.value > 0 && quantity === "0") { setQuantity("") } }

  const userData = useSelector((state) => state.login.userData);
  const lastItemScore = useSelector((state) => state.annScore.lastItemScore);
  const categoriesProduct = useSelector((state) => state.general.categoriesProduct);
  const categoriesProductId = useSelector((state) => state.general.categoriesProductId);
  const sellingAreaExcludedISO = useSelector((state) => state.general.sellingAreaExcludedISO);
  const sellingAreaSelectedISO = useSelector((state) => state.general.sellingAreaSelectedISO);
  const [addArea, setAddArea] = useState(false);
  const [sellWholeWorld, setSellWholeWorld] = useState(!sellingAreaExcludedISO && !sellingAreaSelectedISO ? true : false);
  const [excludeCountry, setExcludeCountry] = useState(true);

  const [infoPrice, setInfoPrice] = useState(false);
  const [infoQuantity, setInfoQuantity] = useState(false);

  const scrollRef = useRef();
  const token = localStorage.getItem("token");
  const tkn = decodeToken();


  /* base64 image preview*/
  const [base64Img, setBase64Img] = useState([]);
  let [selectedIndex, setSelectedIndex] = useState(0);
  const [reachMaxNumOfPics, setReachMaxNumOfPics] = useState(false);

  const uploadFile = async (file) => {
    const fileData = new FormData();

    [...Array(file.length)].map((el, index) => {
      fileData.append('img', file[index]);
    })

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/fileupload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: fileData
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  };

  const moveLeft = () => {
    let newIndex = 0;
    let removeIndex = 0;
    let baseArray = base64Img;
    base64Img.map((el, index) => {
      newIndex = selectedIndex - 1;
      removeIndex = selectedIndex + 1;
      if (newIndex >= 0 && index === selectedIndex) {
        baseArray.splice(newIndex, 0, el);
        baseArray.splice(removeIndex, 1);
        setBase64Img([...baseArray]);
        setBase64Img(baseArray);
        setSelectedIndex(selectedIndex - 1);
      }
    })
  };

  const moveRight = () => {
    let newIndex = 0;
    let removeIndex = 0;
    let baseArray = base64Img;

    base64Img.map((el, index) => {
      newIndex = selectedIndex + 2;
      removeIndex = selectedIndex;
      if (newIndex <= base64Img.length && index === selectedIndex) {
        baseArray.splice(newIndex, 0, el);
        baseArray.splice(removeIndex, 1);
        setBase64Img([...baseArray]);
        setBase64Img(baseArray);
        setSelectedIndex(selectedIndex + 1);
      }
    })
  };

  const uploadBase64 = async (e) => {
    let img64Arry = [];
    const numOfImgs = e.target.files.length;
    const maxNumOfImgs = numOfImgs > 12 ? 12 : numOfImgs;

    [...Array(maxNumOfImgs)].map(async (el, index) => {
      const myFile = e.target.files[index];
      const base64 = await convertBase64(myFile);
      img64Arry.push(base64);
      setBase64Img(img64Arry);
    })
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  };

  const formCheck = async () => {

    const formFilled = brandName && modelName && quantity && file && description && categoriesProduct;

    if (formFilled) {
      setIsFormFilled(true);
      setIsFirstTry(false);
      const uploadedFile = await uploadFile(file);
      handleCreateFormAnnouncement(uploadedFile)
      if (totalPrice !== "0") {
        navigate(`/paymentmethods/xlf-${totalPrice}-jK$`)
      }
    } else {
      setIsFormFilled(false);
      setIsFirstTry(false);
    }

  };

  const handleCreateFormAnnouncement = async (input) => {

    const payload = {
      idOwner: idOwner,
      slotCode: slotCode,
      status: 0,
      relevance: slotCode,
      rejReasons: "",
      brandName: brandName,
      manufacturerName: manufacturerName,
      modelName: modelName,
      quantity: quantity,
      price: price,
      currency: currency,
      sellingAreaExcluded: sellingAreaExcludedISO.map((el) => { return el.split(":")[1] }),
      sellingAreaSelected: sellWholeWorld ? [] : sellingAreaSelectedISO.map((el) => { return el.split(":")[1] }),
      pics: input.img.toString(),
      productSize: productSize,
      description: description,
      techDetail: techDetail.toString(),
      category: categoriesProductId.toString(),
      expireDate: expireDate,
      views: 0,
      posClick: 0,
      negClick: 0,
      likesArry: "",
      newLikes: "",
      dataApproved: "",
      dataRejected: ""
    }
    if (totalPrice === "0") {
      dispatch(postCreateAnnouncementFunc({ payload: payload, token: localStorage.getItem("token") }))
        .then((response) => response.payload.statusCode === 200 ? navigate("/announcements") : setErrorMessage(response.payload.message))
    } else {
      dispatch(saveAnnouncementPayload(payload))
    }

  };

  const resizeFile = async (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1300,
        1100,
        "JPEG",
        file.size > 200000 ? file.size > 600000 ? file.size > 5000000 ? 30 : 40 : 70 : 90,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const myResize = async (e) => {
    let imgArray = [];
    const numOfImgs = e.target.files.length;
    const maxNumOfImgs = numOfImgs > 12 ? 12 : numOfImgs;

    [...Array(maxNumOfImgs)].map(async (el, index) => {
      const myFile = e.target.files[index];
      const image = await resizeFile(myFile);
      imgArray.push(image);
      setFile(imgArray);
    });
    if (numOfImgs > 12) { setReachMaxNumOfPics(true) }
  };

  const manageCategory = (input) => {
    const { el, e } = input;
    if (categoriesProductId.length < 7 || categoriesProductId.includes(el.id)) {
      if (e.currentTarget.classList.toggle('borderActive')) {
        dispatch(setCategoriesProduct(el));
      } else {
        dispatch(delCategoriesProduct(el.id))
      }
    }
  };

  const manageStep = (input) => {
    if (input && step < 5) {
      setStep(step + 1)
    }
    if (!input && step > 0) {
      setStep(step - 1)
    }
  };

  const manageTechDetail = () => {
    if (techDetail.length < 20) {
      if (techDetail.length) {
        let newTechDetailArry = [];
        techDetail.map((el, index) => {
          const splittedElValue = el.split("£")[1];
          newTechDetailArry.push([index, splittedElValue].join("£"));
        })
        setTechDetail([...newTechDetailArry, `${techDetail.length}£` + techDetailView])
      } else {
        setTechDetail([`${techDetail.length}£` + techDetailView])
      }
    }
  };

  const deleteTechItem = (input) => {
    let newTechDetailArry = [];
    techDetail.map((el) => {
      if (el.split("£")[0] !== input) {
        newTechDetailArry.push(el);
      }
    })
    setTechDetail(newTechDetailArry)
  };

  const previewScore = () => {
    dispatch(generateScore(
      {
        idOwner: idOwner,
        slotCode: slotCode,
        status: 0,
        relevance: slotCode,
        rejReasons: "",
        brandName: brandName,
        manufacturerName: manufacturerName,
        modelName: modelName,
        quantity: quantity,
        price: price,
        currency: currency,
        sellingAreaExcluded: sellingAreaExcludedISO.map((el) => { return el.split(":")[1] }),
        sellingAreaSelected: sellWholeWorld ? [] : sellingAreaSelectedISO.map((el) => { return el.split(":")[1] }),
        pics: [...Array(file.length)].toString(),
        productSize: productSize,
        description: description,
        techDetail: techDetail.toString(),
        category: categoriesProductId.toString(),
        expireDate: expireDate,
        views: 0,
        posClick: 0,
        negClick: 0,
        likesArry: "",
        newLikes: "",
        dataApproved: "",
        dataRejected: ""
      }
    ));
  };

  useEffect(() => {
    if (token) {
      dispatch(getSingleUserFunc({ id: tkn.id, token: token }));
      dispatch(clearScore())
      setIdOwner(tkn.id)//verificare se serve
    }
  }, []);

  useEffect(() => {
    setScore(lastItemScore)
  }, [lastItemScore]);

  useEffect(() => {
    if (userData.length) {

      if (userData[0].sellingAreaExcluded) {
        userData[0].sellingAreaExcluded.split(",").map((el) => {
          countriesArray && countriesArray.iso2.map((item) => {
            if (item.split(":")[1] === el) { dispatch(setSellingAreaExcluded(item)); }
          })
        })
      }
      if (userData[0].sellingAreaSelected) {
        userData[0].sellingAreaSelected.split(",").map((el) => {
          countriesArray && countriesArray.iso2.map((item) => {
            if (item.split(":")[1] === el) { dispatch(setSellingAreaSelected(item)); }
          })
        });
        setExcludeCountry(false)
      }
    }
  }, [userData]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [step]);



  return (

    <div className='d-flex myMinVh100 justify-content-center myBgBlack text-light pb-5' ref={scrollRef}>
      <div className='myMaxW700 w-100 py-5'>

        <h1 className='text-center'>New Product</h1>
        {/* progress bar */}
        <div className='d-flex justify-content-center mt-3'>
          <div className='myMaxW300 position-relative progressStepBar d-flex justify-content-center align-items-center'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className={`${step >= 0 ? "active" : ""}`}></div>
              <div className={`${step >= 1 ? "active" : ""}`}></div>
              <div className={`${step >= 2 ? "active" : ""}`}></div>
              <div className={`${step >= 3 ? "active" : ""}`}></div>
              <div className={`${step >= 4 ? "active" : ""}`}></div>
              <div className={`${step >= 5 ? "active" : ""}`}></div>
            </div>
            <div className='position-absolute'></div>
            <div className='position-absolute'
              style={{
                background: `${step === 0 ?
                  "linear-gradient(to right, #f0bd35 10%, #666 10%, #666, #666)"
                  : step === 1 ?
                    "linear-gradient(to right, #f0bd35 30%, #666 10%, #666)"
                    : step === 2 ?
                      "linear-gradient(to right, #f0bd35 50%, #666 10%)"
                      : step === 3 ?
                        "linear-gradient(to right, #f0bd35 70%, #666 10%)"
                        : step === 4 ?
                          "linear-gradient(to right, #f0bd35 90%, #666 10%)"
                          : step === 5 ?
                            "linear-gradient(to right, #f0bd35 100%, #666 10%)"
                            : "#f0bd35"
                  }`
              }}></div>
          </div>
        </div>

        <Form className='px-3' encType='multipart/form-data'>
          <hr className='myDarkGrayColor' />
          {
            step === 0 ?
              <div>
                <Form.Group className="my-5 d-flex align-items-center justify-content-center">
                  <label className='myCursor myInputFile myBgDarkGray rounded-5 d-flex align-items-center' htmlFor="upload-input">
                    <div className='myBgYellow fw-bold text-dark p-1 px-3 rounded-5'>choose a file</div>
                    <p className=' m-0 p1 px-3'>{!file ? "select one or multiple images" : file.length == 1 ? `${JSON.stringify(file.length)} image selected` : `${JSON.stringify(file.length)} images selected`}</p>
                  </label>
                  <input type='file' style={{ display: "none" }} id="upload-input" multiple onChange={(e) => { myResize(e); uploadBase64(e) }} />
                </Form.Group>


                {base64Img.length ? <h4 className='fw-normal mb-2 text-center'>{base64Img.length} new image{base64Img.length > 1 ? "s" : ""}</h4> : null}
                <div className='d-flex justify-content-center flex-wrap myOverFlowXHidden mb-3'>
                  {
                    base64Img && base64Img.map((el, index) => {
                      return <div key={`formannouncement1-${index}`} className={`mySquare100 myOverflowYHidden d-flex align-items-center border myCursor ${selectedIndex === index ? "borderActiveYellow" : ""}`} onClick={() => { setSelectedIndex(index) }} >
                        {!index ? <div className='position-absolute myYellowColor d-flex align-items-start justify-content-start mySquare100' style={{ background: "rgb(30,30,30,.5)", transform: "translateX(-2px)" }}><h3 className='ms-1 pt-1 ps-1 fw-bold'>1°</h3></div> : null}
                        <img className='w-100' src={el} alt="" />
                      </div>
                    })
                  }
                </div>


                {
                  base64Img.length ?
                    <div className='d-flex justify-content-center mb-4'>
                      <div className='d-flex justify-content-around myMaxW300 w-100'>
                        <i className="bi bi-arrow-left px-2 py-1 rounded-5 myBgDarkGray myCursor" onClick={moveLeft}></i>
                        <i className="bi bi-arrow-right px-2 py-1 rounded-5 myBgDarkGray myCursor" onClick={moveRight}></i>
                      </div>
                    </div>
                    : null
                }

                {
                  reachMaxNumOfPics ? <h6 className='myLightGrayColor px-3 text-center'><i className="bi bi-exclamation-triangle-fill"></i> Any product can have a maximum of 12 pictures</h6> : null
                }

              </div>
              : null
          }

          {
            step === 1 ?
              <div>

                <div className='px-2 my-5'>
                  <Form.Group className="mb-3">
                    {brandName ? <Form.Label className='ms-3'>Brand Name</Form.Label> : null}
                    <Form.Control maxLength={250} type="text" placeholder="Brand name" className="form-control" id="brandName" value={brandName} onChange={(e) => setBrandName(e.target.value)} />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    {modelName ? <Form.Label className='ms-3'>Model Name</Form.Label> : null}
                    <Form.Control maxLength={250} type="text" placeholder="Model name" className="form-control" id="modelName" value={modelName} onChange={(e) => setModelName(e.target.value)} />
                  </Form.Group>

                </div>

                <Form.Group >
                  <Form.Label className='px-3 w-100 d-flex justify-content-between'><i className="bi bi-currency-exchange"> Total Price</i> <i className="bi bi-info-circle-fill myLightGrayColor myCursor" onClick={() => setInfoPrice(!infoPrice)}></i></Form.Label>
                  {infoPrice ? <p className='px-3'>Refers to the total price of the entire product stock (multiple items). If you want to sell a single product, total price refers to the price of the single item.</p> : null}
                  <div className='d-flex justify-content-start mb-1 ms-3'>
                    <Form.Check checked={price === "0" ? true : false} type="switch" id="custom-switch" label="Don't show the Price" onChange={() => { setPrice(price === "0" ? "" : "0") }} />
                  </div>
                  {
                    price === "0" ?
                      null :
                      <Form.Control type="number" placeholder="Price" className="form-control" id="price" value={price} onChange={(e) => { if (e.target.value.length < 10) { setPrice(e.target.value); checkQuantity(e) } }} />
                  }
                </Form.Group>

                <Dropdown size="sm">
                  <Dropdown.Toggle variant="warning" id="dropdown-basic" className='mt-2 mx-3 mb-4 ' >
                    <span className='myFontSize16px text-wrap fw-bold' style={{ overflowX: "hidden" }}>{currency ? `${currency.split(":")[1]} (${currency.split(":")[0]})` : "Choose Currency"}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className='fw-bold text-light bg-danger rounded-5 text-center mb-3' onClick={() => setCurrency("")}>Clear Currency <i className="bi bi-trash3-fill"></i></Dropdown.Item>
                    {
                      commonCurrencyJSON && commonCurrencyJSON.map((el, index) => {
                        return <Dropdown.Item key={`formannouncement2-${index}`} className='text-wrap' onClick={() => setCurrency(`${el.name}:${el.symbol}:${el.code}`)}>{el.name} - {el.symbol}</Dropdown.Item>
                      })
                    }
                  </Dropdown.Menu>
                </Dropdown>

                <Form.Group className="mb-3 ">
                  <Form.Label className='px-3 d-flex justify-content-between'><i className="bi bi-box-seam-fill"> Quantity</i> <i className="bi bi-info-circle-fill myLightGrayColor myCursor" onClick={() => setInfoQuantity(!infoQuantity)}></i></Form.Label>
                  {infoQuantity ? <p className='px-3'>Refers to the numbers of items in the stock. If you enter the price you must also enter the quantity.</p> : null}
                  <div className='d-flex justify-content-start mb-1 ms-3'>
                    <Form.Check checked={price > 0 ? false : quantity === "0" ? true : false} type="switch" id="custom-switch" label="Don't show the Quantity" onChange={(e) => { if (!+price) { setQuantity(quantity === "0" ? "" : "0") } }} />
                  </div>
                  {
                    quantity === "0" ?
                      null :
                      <Form.Control type="number" placeholder="Quantity" className="form-control" id="quantity" value={quantity} onChange={(e) => { if (e.target.value === "0" && +price > 0) { return } else { if (e.target.value.length < 10) { setQuantity(e.target.value) } } }} />
                  }
                </Form.Group>


                <div className='text-center mt-2 mb-3'><span className=' display-6 py-2'>{price && quantity && quantity != 0 ? (Math.round(price * 1000 / quantity) / 1000) : 0} {currency.split(":")[1]} /item</span></div>


                <div className='px-2 my-5'>
                  <Form.Group className='mb-3 pt-2'>
                    {productSize ? <Form.Label className='ms-3'>Product Size</Form.Label> : null}
                    <div className='d-flex justify-content-start ms-3 mb-1'>
                      <Form.Check checked={productSize === "Spaceless" ? "checked" : ""} type="switch" id="custom-switch" label="The product is spaceless" onChange={() => { setProductSize(productSize === "Spaceless" ? "" : "Spaceless") }} />
                    </div>
                    <Form.Control maxLength={250} disabled={productSize === "Spaceless" ? true : false} type='text' placeholder='Product size' className='form-control' id="productSize" value={productSize} onChange={(e) => setProductSize(e.target.value)} />
                  </Form.Group>
                </div>
              </div>
              : null
          }

          {
            step === 2 ?
              <Form.Group className="mb-3">
                <div className='d-flex flex-wrap justify-content-center w-100 pt-2 pb-3'>
                  <div>
                    <h2 className='text-center px-2 mb-4 '>Select up to 7 categories</h2>

                    <h6 className='px-3 text-center fw-light mb-5'><i className="bi bi-exclamation-diamond-fill text-warning me-1"></i> Choosing <b>wrong categories</b> on purpose will result in a <b>worse visibility</b>.</h6>

                    <h4 className='w-100 text-center my-3'>Dental Categories</h4>
                    <div className='d-flex justify-content-center gap-3 mb-3 display-6'>
                      {
                        zoomIn.value && zoomIn.field === "den" ?
                          <i className="bi bi-zoom-out myCursor" onClick={() => { setZoomIn({ field: "den", value: false }) }}></i>
                          : <i className="bi bi-zoom-in myCursor" onClick={() => { setZoomIn({ field: "den", value: true }) }}></i>
                      }
                    </div>
                    <div className={`${zoomIn.value && zoomIn.field === "den" ? "zoomInClass" : "normalZoom"} d-flex flex-wrap justify-content-center`}>
                      {
                        productCategories && productCategories.map((el, index) => {
                          if (el.area === "dental") { return <span key={`formannouncement3-${index}`} className={`text-dark text-center px-2 m-1 rounded-5 myBgAqua myCursor myBorderAcqua ${categoriesProductId.length && categoriesProductId.includes(el.id) && "borderActive"}`} onClick={(e) => { manageCategory({ el, e }) }}>{el.eng}</span> }
                        })
                      }
                    </div>
                    <h4 className='w-100 text-center mt-5 mb-3'>Medical Categories</h4>
                    <div className='d-flex justify-content-center gap-3 mb-3 display-6'>
                      {
                        zoomIn.value && zoomIn.field === "med" ?
                          <i className="bi bi-zoom-out myCursor" onClick={() => { setZoomIn({ field: "med", value: false }) }}></i>
                          : <i className="bi bi-zoom-in myCursor" onClick={() => { setZoomIn({ field: "med", value: true }) }}></i>
                      }
                    </div>
                    <div className={`${zoomIn.value && zoomIn.field === "med" ? "zoomInClass" : "normalZoom"} d-flex flex-wrap justify-content-center`}>
                      {
                        productCategories && productCategories.map((el, index) => {
                          if (el.area === "medical") { return <span key={`formannouncement4-${index}`} className={`text-dark text-center px-2 m-1 rounded-5 myBgRed myCursor myBorderRed ${categoriesProductId.length && categoriesProductId.includes(el.id) && "borderActive"}`} onClick={(e) => { manageCategory({ el, e }) }}>{el.eng}</span> }
                        })
                      }
                    </div>

                  </div>
                </div>

                {
                  categoriesProduct.length > 0 ?
                    <div className='myBgDark rounded-5 mt-3 p-2 mx-2'>
                      <h6 className='text-center mt-2'>Selected category/ies:</h6>
                      <div className='d-flex flex-wrap justify-content-center my-3 normalZoom'>
                        {
                          categoriesProduct && categoriesProduct.map((el, index) => {
                            return <span key={`formannouncement5-${index}`} className={`text-dark p-1 px-3 m-1 rounded-5 d-flex align-iems-center ${el.area == "dental" ? "myBgAqua" : "myBgRed"}`}>{el.eng} </span>
                          })
                        }
                      </div>
                    </div>
                    : null
                }
              </Form.Group>
              : null
          }

          {
            step === 3 ?
              <div className='my-5 px-2'>{/* MANUFACTURERS */}
                <h5 className='mt-4 mb-3 fw-bold text-center'><i className="bi bi-globe-americas"></i> Geographic visibility </h5>
                <div className='d-flex justify-content-center'>
                  <div className='d-flex text-center myBgDarkGray rounded-5 px-1 py-1 mt-3'>
                    <h6 className={`${excludeCountry ? "myBgYellow text-dark" : ""} rounded-5 px-3 py-1 fw-bold myCursor myFontSize14px`} onClick={() => { setExcludeCountry(true) }}>Exclude country</h6>
                    <h6 className={`${excludeCountry ? "" : "myBgYellow text-dark"} rounded-5 px-3 py-1 fw-bold myCursor myFontSize14px`} onClick={() => { setExcludeCountry(false) }}>Select country</h6>
                  </div>
                </div>
                <div className='display-5 my-3 d-flex justify-content-center'>{excludeCountry ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}</div>
                <div className='d-flex justify-content-center'>
                  {
                    excludeCountry ?
                      <p>The <b>Exclude country</b> allows you to make your products visible to Dealers located worldwide, <b>except for Dealers located in those countries you want to exclude from the list.</b></p>
                      :
                      <p>The <b>Select country</b> allows you to make your products visible to Dealers located <b>only in those countries you select from the list.</b></p>
                  }
                </div>
                {
                  excludeCountry ?
                    <>
                      <div className='d-flex justify-content-center'>
                        {
                          addArea ?
                            <DropdownButton className="my-2 w-100" variant='light' id="dropdown-basic-button" title="exclude a country">
                              {
                                countriesArray && countriesArray.iso2.map((el, index) => {
                                  return <Dropdown.Item className='text-wrap' key={`formannouncement6-${index}`} onClick={() => { dispatch(setSellingAreaExcluded(el)); setAddArea(false) }}>{el.split(":")[0]}</Dropdown.Item>
                                })
                              }
                            </DropdownButton>
                            :
                            <div className='d-flex gap-4 align-items-center flex-wrap my-3 myCursor'>
                              <Form.Check checked={sellWholeWorld ? "checked" : ""} type="switch" id="custom-switch" label="I want to sell worldwide" onChange={() => { setSellWholeWorld(!sellWholeWorld); dispatch(clearSellingAreaExcluded()); dispatch(clearSellingAreaSelected()) }} />
                              <div className={`${sellWholeWorld ? "disabledPlus" : null} d-flex align-items-center flex-wrap gap-2 text-warning myCursor`} onClick={() => { setAddArea(true) }}><div className='myBgWhite plusDistributionArea d-flex align-items-center justify-content-center border' ><i className="bi bi-plus-lg text-dark"></i></div>exclude a country</div>
                            </div>
                        }

                      </div>
                      {sellingAreaExcludedISO.length ? <h4 className='text-light fw-light mb-2 mt-4'>Excluded country/ies</h4> : null}
                      <div className='text-light d-flex flex-wrap gap-2'>
                        {
                          sellingAreaExcludedISO && sellingAreaExcludedISO.map((el, index) => {
                            return (
                              <div className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5 distAreaCountry' key={`formannouncement7-${index}`}>
                                {el.split(":")[0]}<i className="bi bi-trash-fill text-danger ms-2 myCursor" onClick={() => { dispatch(delSellingAreaExcluded(el)) }}></i>
                              </div>
                            )
                          })
                        }
                      </div>
                    </>
                    :
                    <>
                      <div className='d-flex justify-content-center'>
                        {
                          addArea ?
                            <DropdownButton className="my-2 w-100" variant='light' id="dropdown-basic-button" title="select a country">
                              {
                                countriesArray && countriesArray.iso2.map((el, index) => {
                                  return <Dropdown.Item className='text-wrap' key={`formannouncement8-${index}`} onClick={() => { dispatch(setSellingAreaSelected(el)); setAddArea(false) }}>{el.split(":")[0]}</Dropdown.Item>
                                })
                              }
                            </DropdownButton>
                            :
                            <div className={`d-flex align-items-center flex-wrap gap-2 text-warning myCursor`} onClick={() => { setAddArea(true) }}><div className='myBgWhite plusDistributionArea d-flex align-items-center justify-content-center border' ><i className="bi bi-plus-lg text-dark"></i></div>select a country</div>
                        }

                      </div>
                      {sellingAreaSelectedISO.length ? <h4 className='text-light fw-light mb-2 mt-4'>Selected country/ies</h4> : null}
                      <div className='text-light d-flex flex-wrap gap-2'>
                        {
                          sellingAreaSelectedISO && sellingAreaSelectedISO.map((el, index) => {
                            return (
                              <div className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5 distAreaCountry' key={`formannouncement9-${index}`}>
                                {el.split(":")[0]}<i className="bi bi-trash-fill text-danger ms-2 myCursor" onClick={() => { dispatch(delSellingAreaSelected(el)) }}></i>
                              </div>
                            )
                          })
                        }
                      </div>
                    </>
                }
              </div>
              :
              null
          }

          {
            step === 4 ?
              <div className='px-2 my-5'>
                <Form.Group className="mb-3">
                  {description ? <Form.Label className='ms-3'>Description</Form.Label> : null}
                  <Form.Control maxLength={2500} type="text" placeholder='Description' as="textarea" rows={5} className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3 mt-5">
                  <Form.Label className='ms-3'>Tech Details</Form.Label>
                  {
                    techDetail.length ?
                      <ul className='myBgDark py-2 rounded-2'>
                        {
                          techDetail.map((el, index) => {
                            return <li key={`formannouncement10-${index}`} className='me-3 mt-2' style={{ borderBottom: "1px solid #222" }}>{el.split("£")[1]}<i className="bi bi-x-lg text-danger ms-2 myCursor" onClick={() => { deleteTechItem(el.split("£")[0]) }}></i></li>
                          })
                        }
                      </ul>
                      : null
                  }
                  <div className='d-flex gap-1 rounded-5 align-items-center pe-1'>
                    <Form.Control maxLength={250} type="text" placeholder='add a feature and click plus' className="form-control" id="techDetail" value={techDetailView} onChange={(e) => { if (e.nativeEvent.data !== "£" && e.nativeEvent.data !== ",") { setTechDetailView(e.target.value) } else { setTechDetailValueError(true) } }} />
                    <button className={`rounded-5 ${techDetailView ? "myBgYellow text-dark" : "bg-secondary text-light"}`} disabled={techDetailView ? false : true} onClick={() => { manageTechDetail(); setTechDetailView("") }}><i className="bi bi-plus-lg" ></i></button>
                  </div>
                  {techDetailValueError ? <div className='mt-2 ms-3'>The "£" and "," values are not permitted!</div> : null}
                </Form.Group>
              </div>
              : null
          }



          {/* {
            step === 5 ?
              <div>

                <div className='d-flex justify-content-center align-items-center gap-3 mt-5'>
                  <div className=' d-flex align-items-center gap-2'>
                    <div className={`${totalPrice === '0' ? 'myBorderActive myGradientFreely' : 'bg-secondary'} p-3 py-2 rounded-5 myCursor text-light`} onClick={() => { setTotalPrice("0"); setSlotCode(1) }}>Freely</div>
                    <div className={`${totalPrice === '5.99' ? 'myBorderActive myGradientWeekly' : 'bg-secondary'} p-3 py-2 text-light rounded-5 myCursor`} onClick={() => { setTotalPrice("5.99"); setSlotCode(2) }}>Weekly</div>
                    <div className={`${totalPrice === '12.99' ? 'myBorderActive myGradientMonthly' : 'bg-secondary'} p-3 py-2 text-light rounded-5 myCursor`} onClick={() => { setTotalPrice("12.99"); setSlotCode(3) }}>Monthly</div>
                  </div>
                </div>

                <div className='d-flex justify-content-center mt-4'>
                  {
                    slotPrices.map((el, index) => {
                      if (el.id === slotCode) {
                        return <div key={`formannouncement11-${index}`}><SlotCardAnn el={el} /></div>
                      }
                    })
                  }
                </div>

                <div className='d-flex justify-content-center mt-4'>
                  <Button className='mb-2 rounded-5 px-4' onClick={formCheck}>{totalPrice !== "0" ? <i className="bi bi-credit-card-2-back-fill"> Purchase</i> : <i className="bi bi-send-fill"> Publish</i>} </Button>
                </div>

              </div>
              : null
          } */}

          {
            step === 5 ?
              <div className='my-5'>
                <h3 className='text-center fw-normal myLightGrayColor'>Product Visibility Score</h3>
                <div className='d-flex justify-content-center'>
                  <div className='w-100 d-flex align-items-center mt-2 px-2 myMaxW600'>
                    <div className='myBgDarkGray m-0 w-100 rounded-5' style={{ height: "8px" }}>
                      <div className={`scoreBarLow rounded-5 h-100 ${score > 91 ? "scoreBarLegend" : score > 79 ? "scoreBarHigh" : score > 59 ? "scoreBarMedium" : null}`} style={{ width: `${score}%` }}></div>
                    </div>
                    <h3 className='ms-2 d-flex gap-2 align-items-center noBreak'> <p className='m-0 noBreak'>score:</p> {score / 10}</h3>
                  </div>
                </div>
              </div>
              : null
          }



          <hr className='myDarkGrayColor' />
        </Form>

        <div className='d-flex align-items-center justify-content-center pt-3'>
          {
            step === 0 ?
              <Button className='mb-2 rounded-5 px-4' variant="primary" disabled={file ? false : true} onClick={() => { manageStep(true) }}><i className="bi bi-check2-square me-2"></i>done</Button>
              : step === 1 ?
                <div className='d-flex gap-4 align-items-center mb-5'>
                  <h5 className='bi bi-arrow-return-left myCursor m-0' variant="secondary" onClick={() => { manageStep(false) }}> back</h5>
                  <Button className='mb-2 rounded-5 px-4' variant="primary" disabled={brandName && modelName && quantity && price && isCurrencyNeeded() && productSize ? false : true} onClick={() => { manageStep(true) }}><i className="bi bi-check2-square me-2"></i>done</Button>
                </div>
                : step === 2 ?
                  <div className='d-flex gap-4 align-items-center mb-5'>
                    <h5 className='bi bi-arrow-return-left myCursor m-0' variant="secondary" onClick={() => { manageStep(false) }}> back</h5>
                    <Button className='mb-2 rounded-5 px-4' variant="primary" disabled={categoriesProduct.length > 0 ? false : true} onClick={() => { manageStep(true) }}><i className="bi bi-check2-square me-2"></i>done</Button>
                  </div>
                  : step === 3 ?
                    <div className='d-flex gap-4 align-items-center mb-5'>
                      <h5 className='bi bi-arrow-return-left myCursor m-0' variant="secondary" onClick={() => { manageStep(false) }}> back</h5>
                      <Button className='mb-2 rounded-5 px-4' variant="primary" disabled={sellingAreaExcludedISO.length > 0 || sellingAreaSelectedISO.length > 0 || sellWholeWorld ? false : true} onClick={() => { manageStep(true) }}><i className="bi bi-check2-square me-2"></i>done</Button>
                    </div>
                    : step === 4 ?
                      <div className='d-flex gap-4 align-items-center mb-5'>
                        <h5 className='bi bi-arrow-return-left myCursor m-0' variant="secondary" onClick={() => { manageStep(false) }}> back</h5>
                        <Button className='mb-2 rounded-5 px-4' variant="primary" disabled={description && techDetail ? false : true} onClick={() => { manageStep(true); previewScore() }}><i className="bi bi-check2-square me-2"></i>done</Button>
                      </div>
                      : step === 5 ?
                        <div className='d-flex gap-4 align-items-center my-5'>
                          <h5 className='bi bi-arrow-return-left myCursor m-0' variant="secondary" onClick={() => { manageStep(false) }}> back</h5>
                          <Button className='mb-2 rounded-5 px-4' onClick={formCheck}>{totalPrice !== "0" ? <i className="bi bi-credit-card-2-back-fill"> Purchase</i> : <i className="bi bi-send-fill"> Publish</i>} </Button>
                        </div>
                        : null
          }
        </div>

        {
          !isFirstTry && !isFormFilled ? <Alert className='mt-3' key="danger" variant="danger">
            Fill the form correctly!
          </Alert> : null
        }
        {
          errorMessage && <div className='myMaxW700 bg-danger fw-bold m-2 p-3'>{errorMessage}</div>
        }
        {/* <Link to='/payment'><button className="btn btn-primary m-3">payment</button></Link> */}
      </div >
    </div >


  )
}


export default _FormAnnouncement
