import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import AccountEditAnagraphic from './AccountEditAnagraphic';
import { getInvoiceAnagrapicFunc, setInvoiceEditingState } from '../states/invoiceAnagraphics';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import PaypalSubscribe from './PaypalSubscribe';
import StripeSubscribe from './StripeSubscribe';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);



const Checkout = ({ product }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const invoiceAnagraphic = useSelector((state) => state.invoiceAnagraphic.invoiceAnagraphic);
    const isEditingInvoiceAnagraphic = useSelector((state) => state.invoiceAnagraphic.isEditingInvoiceAnagraphic);
    const reloadAnagraphic = useSelector((state) => state.invoiceAnagraphic.reloadAnagraphic);
    const isLoading = useSelector((state) => state.invoiceAnagraphic.isLoading);
    const dcdTkn = useSelector((state) => state.general.dcdTkn);
    const userData = useSelector((state) => state.login.userData);
    const [areInvoiceData, setAreInvoiceData] = useState(userData[0] ? true : false);
    const [readyToPay, setReadyToPay] = useState(false);

    const appearance = {
        theme: 'stripe'
    };

    const stripeOptions = {
        clientSecret: process.env.STRIPE_PRIVATE_KEY,
        mode: 'payment',
        amount: 1099,
        currency: 'usd',
        appearance
    };


    useEffect(() => {
        if (!isEditingInvoiceAnagraphic) {
            dispatch(getInvoiceAnagrapicFunc({ idOwner: dcdTkn.id, token: localStorage.getItem("token") }));
        }
    }, [isEditingInvoiceAnagraphic, reloadAnagraphic])

    useEffect(() => {
        if (!areInvoiceData) { navigate("/") }
    }, [areInvoiceData, userData])





    return (
        <div className='pt-5 mb-5' style={{paddingBottom: "50px"}}>
            <div className='d-flex align-items-center flex-column gap-2'>

                <div className='d-flex flex-column align-items-center'>

                    {
                        product.type === "PREMIUM" ?
                            <div className='myBgDark d-flex align-items-center justify-content-center packageIcon100px'><i className="bi bi-lightning-charge-fill myYellowColor myFontSize3Em" ></i></div>
                            : product.type === "BUSINESS" ?
                                <div className='myBgDark d-flex align-items-center justify-content-center packageIcon100px'><i className="bi bi-droplet-fill myAquaColor myFontSize3Em" ></i></div>
                                : product.type === "SELLER" ?
                                    <div className='myBgDark d-flex align-items-center justify-content-center packageIcon100px'><i className="bi bi-fire myFucsiaRed myFontSize3Em" ></i></div>
                                    : product.type === "WEEKLY" ?
                                        <div className='myGradientWeekly d-flex align-items-center justify-content-center packageIcon100px'><i className="bi bi-7-circle-fill myFontSize3Em" ></i></div>
                                        : product.type === "MONTHLY" ?
                                            <div className='myGradientMonthly d-flex align-items-center justify-content-center packageIcon100px'><i className="bi bi-moon-stars-fill myFontSize3Em" ></i></div>
                                            : null

                    }
                    <div className='d-flex justify-content-center align-items-center gap-3 mt-5'>
                        <h2 className='fw-light'>Product:</h2>
                        <div className={`fw-normal text-light ${product.type === "WEEKLY" ? "myGradientWeekly" : product.type === "MONTHLY" ? "myGradientMonthly" : "myBgDark"} rounded-5 px-3 py-1`}>
                            {
                                product.type === "PREMIUM" ?
                                    <div><i className="bi bi-lightning-charge-fill myYellowColor me-1"></i>PREMIUM</div>
                                    : product.type === "BUSINESS" ?
                                        <div><i className="bi bi-droplet-fill myAquaColor me-2"></i>BUSINESS</div>
                                        : product.type === "SELLER" ?
                                            <div><i className="bi bi-fire myFucsiaRed me-1"></i>SELLER</div>
                                            : product.type === "WEEKLY" ?
                                                <div className='text-dark fw-bold'><i className="bi bi-7-circle-fill me-1"></i>WEEKLY</div>
                                                : product.type === "MONTHLY" ?
                                                    <div className='text-dark fw-bold'><i className="bi bi-moon-stars-fill me-1"></i>MONTHLY</div>
                                                    : null
                            }
                        </div>
                    </div>
                </div>

                <div className='d-flex justify-content-center align-items-baseline gap-1'>
                    <h2 className='fw-light'>Price: <span className='display-6'>{product.price * product.quantity} {product.currency}</span></h2>
                    <h6>/month</h6>
                </div>
            </div>

            {
                readyToPay ?
                    <div className='d-flex justify-content-center py-3 pt-5'>
                        <div className='border ps-2 py-2 pe-3 bg-dark text-light rounded-5'>
                            <div className='d-flex align-items-center'>
                                <h3 className='fw-normal myBgLightGray rounded-5 px-4 py-1 text-dark'>Invoice data</h3>
                                <h3><i className="bi bi-check-circle-fill myChatColor ms-3"></i></h3>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        {
                            userData[0] &&
                            <div className='d-flex justify-content-center mt-5'>
                                <div className='border bg-light p-4 pt-3 invoiceDataTable'>
                                    <h3 className='text-center mb-3 text-secondary'>Invoice data</h3>

                                    {
                                        isLoading ?
                                            <div className='d-flex justify-content-center'>
                                                <Spinner className='text-secondary fw-light' animation="border" size='sm' />
                                            </div>
                                            :
                                            <div>
                                                {
                                                    invoiceAnagraphic.length ?
                                                        <div>
                                                            {
                                                                isEditingInvoiceAnagraphic ?
                                                                    <AccountEditAnagraphic userData={invoiceAnagraphic[0]} isInvoiceAnagraphic={true} />
                                                                    :
                                                                    <div>
                                                                        <h6>Company Name: <span>{invoiceAnagraphic[0].companyName}</span></h6>
                                                                        {invoiceAnagraphic[0].vat ? <h6>VAT: <span>{invoiceAnagraphic[0].vat}</span></h6> : null}
                                                                        {invoiceAnagraphic[0].taxId ? <h6>Tax ID: <span>{invoiceAnagraphic[0].taxId}</span></h6> : null}
                                                                        {invoiceAnagraphic[0].sdi ? <h6>SDI: <span>{invoiceAnagraphic[0].sdi}</span></h6> : null}
                                                                        {invoiceAnagraphic[0].pec ? <h6>pec: <span>{invoiceAnagraphic[0].pec}</span></h6> : null}
                                                                        <hr />
                                                                        <h6>Country: <span>{invoiceAnagraphic[0].country}</span></h6>
                                                                        <h6>City: <span>{invoiceAnagraphic[0].city}</span></h6>
                                                                        <h6>State: <span>{invoiceAnagraphic[0].state}</span></h6>
                                                                        {/* <h6>Address: <span>{invoiceAnagraphic[0].address}, {invoiceAnagraphic[0].streetNumber} - ({invoiceAnagraphic[0].zipCode})</span></h6> */}
                                                                    </div>
                                                            }
                                                        </div>
                                                        :
                                                        <AccountEditAnagraphic userData={userData[0]} isInvoiceAnagraphic={true} />
                                                }
                                            </div>
                                    }

                                    {
                                        invoiceAnagraphic.length ?
                                            <div>
                                                <hr className='my-4 w-100' />
                                                <div className='d-flex justify-content-center'>
                                                    <Button className='rounded-5 btn btn-light fw-bold myBgLightGray text-dark '>
                                                        <i className={`bi ${isEditingInvoiceAnagraphic ? "bi-caret-left-fill" : "bi-pencil-fill"}`} onClick={() => dispatch(setInvoiceEditingState(!isEditingInvoiceAnagraphic))}>{isEditingInvoiceAnagraphic ? " back" : " change"}</i>
                                                    </Button>
                                                </div>
                                            </div>
                                            : null
                                    }

                                </div>
                            </div>
                        }
                    </div>
            }

            {
                readyToPay ?
                    <div>
                        {/*  <PaypalPayment product={product}/> */}
                        <Elements stripe={stripePromise} options={stripeOptions}>
                            <StripeSubscribe product={product} />
                        </Elements>
                        <PaypalSubscribe product={product} />
                    </div>
                    : null
            }

            {
                !isEditingInvoiceAnagraphic && invoiceAnagraphic.length ?
                    !readyToPay ?
                        <div className='d-flex justify-content-center mt-3'>
                            <Button variant='light' className={`text-light ${product.id === 1 ? "myBgYellow" : product.id === 2 ? "myBgAqua" : product.id === 3 ? "myBgFucsiaRed" : product.id === 11 ? "myGradientWeekly" : "myGradientMonthly"} px-5 py-2 rounded-0`} onClick={() => setReadyToPay(true)}>
                                <div className='fw-light'>
                                    {isLoading ? <Spinner animation="border" size='sm' /> : <div><h3 className='fw-normal d-flex align-items-center gap-2'>Pay <i className="bi bi-credit-card-2-back-fill ms-1"></i></h3></div>}
                                </div>
                            </Button>
                        </div>
                        :
                        <div className='d-flex justify-content-center mt-3'>
                            <Button variant="btn btn-secondary px-5 py-2 rounded-0" onClick={() => setReadyToPay(false)}>
                                <div className='fw-light'>
                                    {isLoading ? <Spinner animation="border" size='sm' /> : <h3 className='fw-normal pe-3'><i className="bi bi-chevron-left"></i> Back</h3>}
                                </div>
                            </Button>
                        </div>
                    :
                    null
            }



        </div>
    )
}

export default Checkout