import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { verifyCredentialsFunc } from '../states/loginState';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';
import { changePsswFunc, psswChangedMailFunc } from '../states/signinState';
import JSEncrypt from 'jsencrypt';


const PsswChange = ({ param }) => {
  const [pssw, setPssw] = useState('');
  const [pssw2, setPssw2] = useState('');
  const [psswR, setPsswR] = useState('');
  const [serverResponse, setServerResponse] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginLoading = useSelector((state) => state.login.loading)

  const specialCharacter = ["!", "#", "$", "%", "&", "@", "<", ">", "="];
  const isPsswValid = pssw.split("").some((x) => specialCharacter.includes(x)) && pssw.length > 7;
  const isPssw2Valid = pssw2.split("").some((x) => specialCharacter.includes(x)) && pssw2.length > 7;
  const isPsswRValid = pssw2 === psswR;
  const formOk = param && isPsswValid;
  const form2Ok = param && isPssw2Valid && isPsswRValid;
  const identicalPssws = pssw === pssw2;

  const token = localStorage.getItem("token");



  const validatePssw = async () => {

    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);
    const encryptedPssw = encrypt.encrypt(pssw.trim());

    let loginPayload = {
      cryptedString: param.string,
      pssw: encryptedPssw
    }
    
    if (formOk) {
      dispatch(verifyCredentialsFunc({ payload: loginPayload, token: token }))
        .then((res) => {
          if (res.payload.statusCode === 200) {
            setStep(1);
            if (serverResponse) { setServerResponse("") };
          } else {
            setServerResponse(res.payload.message);
          }
        })
        .catch((err) => {
          console.log('login or server error', err);
        })
    }
  };

  const changeMyPssw = async () => {
    if (form2Ok) {
      if (identicalPssws) {
        setError("New password must be different from the old one!")
      } else {
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);
        const encryptedPssw = encrypt.encrypt(pssw2.trim());

        dispatch(changePsswFunc({ payload: { pssw: encryptedPssw, cryptedString: param.string }, token: localStorage.getItem("token") }))
          .then((res) => {
            if (res.payload.statusCode === 200) {
              setStep(2)
              setTimeout(() => {
                navigate("/login")
              }, "3000")
            } else {
              setServerResponse(res.payload.message)
            }
          })
      }
    }
  }


  return (
    <div className='d-flex align-items-center justify-content-center' style={{ height: "100vh" }}>
      <div className='myMaxW700 p-3'>

        {
          step === 0 ?
            <div>
              <h3 className='text-center myMidGrayColor mb-3'>Old Password</h3>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><i className="bi bi-key-fill"></i></InputGroup.Text>
                <Form.Control type='password' placeholder="Password" aria-label="Password" aria-describedby="basic-addon1"
                  onChange={(e) => setPssw(e.target.value)}
                  value={pssw}
                  maxLength={26}
                />
              </InputGroup>

              <div className='d-flex align-items-center justify-content-center pt-3'>
                <Button className='rounded-5 px-4' variant="primary" disabled={isPsswValid ? false : true} onClick={() => { validatePssw() }}><i className="bi bi-check2-square me-2"></i>{loginLoading ? <Spinner animation="border" size='sm' /> : "done"}</Button>
              </div>
              {serverResponse ? <p className="mt-3 text-center text-danger"><i className="bi bi-exclamation-circle"> {serverResponse}</i></p> : <p></p>}
            </div>
            : null
        }

        {
          step === 1 ?
            <div>
              <h3 className='text-center myMidGrayColor mb-3'>New Password</h3>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><i className="bi bi-key-fill"></i></InputGroup.Text>
                <Form.Control type='password' placeholder="Password (max 26 characters)" aria-label="Password" aria-describedby="basic-addon1"
                  onChange={(e) => setPssw2(e.target.value)}
                  value={pssw2}
                  maxLength={26}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><i className="bi bi-key-fill"></i></InputGroup.Text>
                <Form.Control type='password' placeholder="Repeat Password (max 26 characters)" aria-label="Password" aria-describedby="basic-addon1"
                  onChange={(e) => setPsswR(e.target.value)}
                  value={psswR}
                  maxLength={26}
                />
              </InputGroup>

              <div className='bg-secondary text-light p-2 px-3'>
                <p className='m-0'>Password must be <b>longer than 7 characters</b>, shorter than 27 & must contain at least one of this special characters: <b>! # $ % & @ =</b></p>
              </div>

              <div className='d-flex align-items-center justify-content-center pt-3'>
                <Button className='rounded-5 px-4' variant="primary" disabled={isPssw2Valid && isPsswRValid ? false : true} onClick={() => { changeMyPssw() }}><i className="bi bi-check2-square me-2"></i>{loginLoading ? <Spinner animation="border" size='sm' /> : "done"}</Button>
              </div>
              {error ? <p className="mt-3 text-center text-danger"><i className="bi bi-exclamation-circle"> {error}</i></p> : <p></p>}
              {serverResponse ? <p className="mt-3 text-center text-danger"><i className="bi bi-exclamation-circle"> {serverResponse}</i></p> : <p></p>}
            </div>
            : null
        }


        {
          step === 2 ?
            <div className='d-flex flex-column align-items-center gap-3 m-3 myChatColor'>
              <h3 className='fw-normal text-center'>Password has been changed succesfully!</h3>
              <i className="bi bi-check-circle-fill display-6"></i>

              <div className='p-4 d-flex flex-column align-items-center w-100'>
                <div className='border border-dark rounded mx-3 myMaxW700 w-100 myBgDarkGray'>
                  <div className={"myBgChat rounded percentageBar"} style={{ height: "6px" }}></div>
                </div>
              </div>

            </div>
            : null
        }
      </div>
    </div>
  )
}

export default PsswChange