import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { getUserOutletFunc, createUserOutletFunc } from '../states/outletStore';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import Spinner from 'react-bootstrap/Spinner';
import { setIsLogged } from '../states/loginState';
import Lottie from 'lottie-react';
import BoxFalling from '../assets/Animation/boxFalling.json';
import decodeToken from '../functions/decodeToken';



const Home = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMi slice'
    }
  }


  const dispatch = useDispatch();
  const navigate = useNavigate()

  const token = localStorage.getItem("token");
  let tkn;

  const enterOutlet = async () => {
    if (token) {
      tkn = decodeToken();

      if (tkn) {
        dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
          .then((res) => {
            if (res.payload.statusCode === 408) {
              dispatch(setIsLogged(false));
              localStorage.clear();
              navigate('/login')
            } else {
              if (res.payload.data.length !== 0) {
                navigate('/store')
              } else {
                firstOutletEnter()
              }
            }
          })
      }
    } else {
      navigate('/login')
    }
  };

  const firstOutletEnter = async () => {
    if (token && tkn) {

      const payload = {
        idOwner: tkn.id,
        outletSet: "",
        outletHistory: "",
        outletLikes: "",
        resetOutletTime: ""
      };
      await dispatch(createUserOutletFunc({ payload: payload, token: token }))
        .then((res) => {
          if (res.payload.statusCode === 200) {
            navigate('/store')
          }
        })
    }
  }

  useEffect(() => {
    enterOutlet()
  }, [])



  return (
    <div>
      <div style={{ minHeight: "calc(100vh - 59px)" }} className='position-relative py-5 px-3 d-flex justify-content-start flex-column'>
        <div className="position-relative w-100 myMaxW500" style={{ left: "50%", transform: "translate(-50%, 50%)" }}>
          <Lottie animationData={BoxFalling} options={defaultOptions} />
        </div>
      </div>
    </div>


  );
}

export default Home;
