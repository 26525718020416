import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAnnouncementsByIdOwnerFunc } from '../states/storeState';
import CardPenRejAnnouncementReduced from './CardPenRejAnnouncementReduced';
import { setIsLogged } from '../states/loginState';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { clearCategoriesProduct } from '../states/generalState';
import decodeToken from '../functions/decodeToken';
import { nanoid } from 'nanoid';

const _Announcements = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allUserAnnouncementsData = useSelector((state) => state.myStore.allData);
    const [allUserAnnouncements, setAllUserAnnouncements] = useState([]);
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const [viewArchived, setViewArchived] = useState(false);
    const [onlineCount, setOnlineCount] = useState(0);
    const [archivedCount, setArchivedCount] = useState(0);

    const tkn = decodeToken();
    const token = localStorage.getItem("token");

    const orderUserAnnouncementsByLikeNotify = (input) => {
        const likeArry = [];
        const noLikeArry = [];
        if (input) {
            input.map((el) => {
                if (el.newLikes && el.newLikes !== "null") {
                    likeArry.push(el)
                } else {
                    noLikeArry.push(el)
                }
            })
            setAllUserAnnouncements(likeArry.concat(noLikeArry))
        }
    };

    useEffect(() => {
        if (allUserAnnouncementsData.length) {
            orderUserAnnouncementsByLikeNotify(allUserAnnouncementsData)
        }
    }, [allUserAnnouncementsData]);

    useState(() => {

        if (token) {
            dispatch(getAllAnnouncementsByIdOwnerFunc({ idOwner: tkn.id, token: token }))
                .then((res) => {
                    if (res.payload.statusCode === 408) {//jwtVerify
                        dispatch(setIsLogged(false));
                        navigate('/login');
                        localStorage.clear()
                    }
                });
        }
    }, []);

    useEffect(() => {
        if (!token) {
            dispatch(setIsLogged(false));
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        if (allUserAnnouncements.length) {
            let myArchivedCount = 0;
            let myOnlineCount = 0;
            allUserAnnouncements.map((el) => {
                if (el.status === 2) {
                    myArchivedCount += 1
                } else {
                    myOnlineCount += 1
                }
                setArchivedCount(myArchivedCount);
                setOnlineCount(myOnlineCount)
            })
        }
    }, [allUserAnnouncements])


    return (

        <div>
            <div className='d-flex justify-content-around align-items-center pt-4 px-3 mb-3 text-light'>
                <div>
                    <Link className='noDecoration' to={"/createannouncement"} onClick={() => { dispatch(clearCategoriesProduct()) }}>
                        <div className='d-flex flex-column align-items-center myYellowColor'>
                            <i className="bi bi-plus-circle-fill display-3"></i>
                            <p className='m-0 fw-bold'>Add New</p>
                        </div>
                    </Link>
                </div>
                <div className='d-flex align-items-center myLightGrayColor justify-content-center mt-1'>
                    {
                        viewArchived ?
                            <div>{archivedCount} items<div>Archived</div></div>
                            : <div>{onlineCount} items<div>Online</div></div>
                    }
                </div>
                <div className='myBgDarkGray px-1 py-1 rounded-5 d-flex gap-1 myCursor'>
                    <i className={`bi bi-house-fill ${viewArchived ? "" : "myBgYellow text-dark"} px-3 rounded-5 myFontSize20px`} onClick={() => setViewArchived(false)}></i>
                    <i className={`bi bi-archive-fill ${viewArchived ? "myBgYellow text-dark" : ""} px-3 rounded-5 myFontSize20px`} onClick={() => setViewArchived(true)}></i>
                </div>
            </div>

            <div className='myBgAbsBlack'>
                {

                    !allUserAnnouncements.length && !isLoading ?
                        <h1 className='mt-5 fw-light text-center text-secondary'>No Products published</h1>
                        :
                        <div className='d-flex flex-wrap justify-content-center align-items-center mb-5 pt-3 pb-5 px-1'>

                            {
                                viewArchived ?
                                    allUserAnnouncements.length && allUserAnnouncements.map((el) => {
                                        const newLikes = el.newLikes && el.newLikes !== "null" ? true : false;
                                        if (el.status === 2) {
                                            return <CardPenRejAnnouncementReduced idOwn={tkn.id} key={nanoid()} newLikes={newLikes} singleData={el} isLoading={isLoading} />
                                        }
                                    })
                                    :
                                    allUserAnnouncements.length && allUserAnnouncements.map((el) => {
                                        const newLikes = el.newLikes && el.newLikes !== "null" ? true : false;
                                        if (el.status !== 2) {
                                            return <CardPenRejAnnouncementReduced idOwn={tkn.id} key={nanoid()} newLikes={newLikes} singleData={el} isLoading={isLoading} />
                                        }
                                    })
                            }
                        </div>

                }
            </div>
        </div >

    )
}

export default _Announcements