import { jwtDecode } from "jwt-decode";

const decodeToken = () => {
    try {     
        const token = localStorage.getItem("token");
        if(token){
            return jwtDecode(token, process.env.JWT_SECRET)
        }
    } catch (error) {
        return false
    }
};

export default decodeToken