import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

const initialState = {
    isHamMenuOpen: false,
    isPenRejModalEditing: { value: false, id: null },
    dcdTkn: { id: "", email: "", interests: "" },
    categoriesProduct: [],
    categoriesProductId: [],
    distributionAreaISO: [],
    sellingAreaExcludedISO: [],
    sellingAreaSelectedISO: []
};


const generalSlice = createSlice({
    name: 'generalStates',
    initialState,
    reducers: {
        setIsHamMenuOpen: (state, action) => {
            state.isHamMenuOpen = action.payload
        },
        setIsPenRejModalEditing: (state, action) => {
            state.isPenRejModalEditing = action.payload
        },
        decodeTkn: (state, action) => {
            const token = localStorage.getItem("token");
            if (token) {
                const tkn = async() => {
                    try {     
                        return jwtDecode(token, process.env.JWT_SECRET)
                    } catch (error) {
                        console.log(error);
                    }
                };
                state.dcdTkn = tkn();
            }
        },
        setCategoriesProduct: (state, action) => {
            let isDuplicated = false;
            state.categoriesProduct && state.categoriesProduct.map((el) => {
                if (el.id === action.payload.id) { isDuplicated = true }
            });

            if (!isDuplicated && state.categoriesProduct.length < 7) {
                state.categoriesProduct.push(action.payload);
                state.categoriesProductId.push(action.payload.id)
            }

        },
        delCategoriesProduct: (state, action) => {
            const cat = [];
            const catId = [];
            state.categoriesProduct.map((el) => {
                if (el.id !== action.payload) {
                    cat.push(el);
                    catId.push(el.id)
                }
            });
            state.categoriesProduct = cat;
            state.categoriesProductId = catId
        },
        clearCategoriesProduct: (state, action) => {
            state.categoriesProduct = [];
            state.categoriesProductId = []
        },
        setDistributionArea: (state, action) => {
            state.sellingAreaExcludedISO = [];
            state.sellingAreaSelectedISO = [];
            let isDuplicated = false;
            state.distributionAreaISO && state.distributionAreaISO.map((el) => {
                if (el === action.payload) { isDuplicated = true }
            });
            if (!isDuplicated && !state.distributionAreaISO.length) { state.distributionAreaISO.push(action.payload); }

        },
        delDistributionArea: (state, action) => {
            const arryCountries = [];
            state.distributionAreaISO && state.distributionAreaISO.map((el) => {
                if (action.payload !== el) {
                    arryCountries.push(el)
                }
            });
            state.distributionAreaISO = arryCountries
        },
        clearDistributionArea: (state, action) => {
            state.distributionAreaISO = [];
        },
        setSellingAreaExcluded: (state, action) => {
            state.distributionAreaISO = [];
            state.sellingAreaSelectedISO = [];
            let isDuplicated = false;
            state.sellingAreaExcludedISO && state.sellingAreaExcludedISO.map((el) => {
                if (el === action.payload) { isDuplicated = true }
            });
            if (!isDuplicated && state.sellingAreaExcludedISO.length < 60) { state.sellingAreaExcludedISO.push(action.payload); }

        },
        delSellingAreaExcluded: (state, action) => {
            const arryCountries = [];
            state.sellingAreaExcludedISO && state.sellingAreaExcludedISO.map((el) => {
                if (action.payload !== el) {
                    arryCountries.push(el)
                }
            });
            state.sellingAreaExcludedISO = arryCountries
        },
        clearSellingAreaExcluded: (state, action) => {
            state.sellingAreaExcludedISO = [];
        },
        setSellingAreaSelected: (state, action) => {
            state.distributionAreaISO = [];
            state.sellingAreaExcludedISO = [];
            let isDuplicated = false;
            state.sellingAreaSelectedISO && state.sellingAreaSelectedISO.map((el) => {
                if (el === action.payload) { isDuplicated = true }
            });
            if (!isDuplicated && state.sellingAreaSelectedISO.length < 60) { state.sellingAreaSelectedISO.push(action.payload); }

        },
        delSellingAreaSelected: (state, action) => {
            const arryCountries = [];
            state.sellingAreaSelectedISO && state.sellingAreaSelectedISO.map((el) => {
                if (action.payload !== el) {
                    arryCountries.push(el)
                }
            });
            state.sellingAreaSelectedISO = arryCountries
        },
        clearSellingAreaSelected: (state, action) => {
            state.sellingAreaSelectedISO = [];
        }
    }
});

export const { setIsHamMenuOpen, setIsPenRejModalEditing, decodeTkn, setCategoriesProduct, delCategoriesProduct, clearCategoriesProduct,
    setDistributionArea, delDistributionArea, clearDistributionArea, setSellingAreaExcluded, delSellingAreaExcluded, clearSellingAreaExcluded,
    setSellingAreaSelected, delSellingAreaSelected, clearSellingAreaSelected } = generalSlice.actions;
export default generalSlice.reducer

