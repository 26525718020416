import JSEncrypt from 'jsencrypt';

const myEncryptCode = async (input) => {
    if (input) {
        console.log(input);
        
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);
        const result = encrypt.encrypt(JSON.stringify(input));
        console.log(result);
        return result
    } else {
        return false
    }
};

export default myEncryptCode