import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleUserFunc } from '../states/loginState';
import { getAllAnnouncementsByIdOwnerFunc, getFavouritesAnnouncementsByIdFunc, setFavouritesData } from '../states/storeState';
import { setIsLogged } from '../states/loginState';
import { useNavigate } from 'react-router';
import { getUserOutletFunc } from '../states/outletStore';
import CardChatAnnouncement from './CardChatAnnouncement';
import ChatAnnouncement from './ChatAnnouncement';
import { getAllChatsNotifyByIdOwnerUserFunc, areThereNotify, goToMyChat, clearUsersById, setFavoritesAnnouncementsId } from '../states/chatState';
import decodeToken from '../functions/decodeToken';
import Spinner from 'react-bootstrap/Spinner';


const _Chat = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allUserAnnouncements = useSelector((state) => state.myStore.allData);
    const [allUserAnnouncementsNotifyOrder, setAllUserAnnouncementsNotifyOrder] = useState(allUserAnnouncements);
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const favouritesData = useSelector((state) => state.myStore.favouritesData);
    const [favouritesDataNotify, setFavouritesDataNotify] = useState(favouritesData);
    const favoritesAnnouncementsId = useSelector((state) => state.chat.favoritesAnnouncementsId);
    const myChatState = useSelector((state) => state.chat.myChatState);
    const notifyCountMyProducts = useSelector((state) => state.chat.notifyCountMyProducts);
    const notifyCountFavourites = useSelector((state) => state.chat.notifyCountFavourites);
    const outletUserData = useSelector((state) => state.outlet.outletUserData);
    const allNewLikesCount = useSelector((state) => state.myStore.allNewLikesCount);
    const allChatsNotify = useSelector((state) => state.chat.allChatsNotify);
    const disableBackArrowChat = useSelector((state) => state.chat.backArrowChatOff);
    const [isFavouritesChat, setIsFavouritesChat] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);
    const [openChat, setOpenChat] = useState(false);
    const [idChat, setIdChat] = useState(null);
    const chatRef = useRef();

    const token = localStorage.getItem("token");
    const tkn = decodeToken();

    const favourtitesChatOrganizer = () => {
        let notifyFavourites = [];
        let notNotifyFavourites = [];
        let notifyFavouritesId = [];
        let notifyFavouritesNewMssgCount = [];
        let notNotifyFavouritesId = [];
        if (favouritesData) {

            allChatsNotify.map((el) => {
                if (el.idUser === tkn.id) {
                    if (!el.userCheck) {
                        notifyFavouritesId.push(el.idAnn);
                        notifyFavouritesNewMssgCount.push({ idAnn: el.idAnn, newMssgCount: el.ownerLength - el.userLength })
                    } else {
                        notNotifyFavouritesId.push(el.idAnn)
                    }
                }
            })

            favouritesData.map((item) => {
                if (notifyFavouritesId.includes(item.id)) {
                    let myMssgCount = 0;
                    notifyFavouritesNewMssgCount.map((el) => { if (el.idAnn === item.id) { myMssgCount = el.newMssgCount } });
                    notifyFavourites.push({ ...item, notify: true, newMssgCount: myMssgCount })
                } else {
                    notNotifyFavourites.push({ ...item, notify: false })
                }

            });
            const myData = notifyFavourites.concat(notNotifyFavourites);

            if (!favoritesAnnouncementsId.length) {
                setFavouritesDataNotify(myData);
            } else {
                const finalFavouritesOrder = [];
                favoritesAnnouncementsId.map((el) => {
                    favouritesDataNotify.map((item) => {
                        if (item.id === +el) {
                            if (notifyFavouritesId.includes(item.id)) {
                                let myMssgCount = 0;
                                notifyFavouritesNewMssgCount.map((el) => { if (el.idAnn === item.id) { myMssgCount = el.newMssgCount } })
                                finalFavouritesOrder.push({ ...item, notify: true, newMssgCount: myMssgCount })
                            } else {

                                finalFavouritesOrder.push({ ...item, notify: false })
                            }
                        }
                    })
                });
                setFavouritesDataNotify(finalFavouritesOrder)
            }
            dispatch(areThereNotify({ chats: allChatsNotify, idOwner: tkn.id }));
        }
    };

    const announcementsChatOrganizer = () => {
        let notifyAnnouncement = [];
        let notNotifyAnnouncement = [];
        let likedAnnouncement = [];
        let notifyAnnouncementsNewMssgCount = [];
        let notifyAnnouncementId = [];
        let notNotifyAnnouncementId = [];
        if (allUserAnnouncements) {

            allChatsNotify.map((el) => {
                if (el.idOwner === tkn.id) {
                    if (!el.ownerCheck) {
                        notifyAnnouncementId.push(el.idAnn);
                        notifyAnnouncementsNewMssgCount.push({ idAnn: el.idAnn, newMssgCount: el.userLength - el.ownerLength })
                    } else {
                        notNotifyAnnouncementId.push(el.idAnn)
                    }
                }
            })

            allUserAnnouncements.map((item) => {
                if (notifyAnnouncementId.includes(item.id)) {
                    let myMssgCount = 0;
                    notifyAnnouncementsNewMssgCount.map((el) => { if (el.idAnn === item.id) { myMssgCount = el.newMssgCount } })
                    notifyAnnouncement.push({ ...item, notify: true, newMssgCount: myMssgCount })
                } else {
                    if (item.newLikes) {
                        likedAnnouncement.push(item)
                    } else {
                        notNotifyAnnouncement.push({ ...item, notify: false })
                    }
                }

            });
            setAllUserAnnouncementsNotifyOrder(notifyAnnouncement.concat(likedAnnouncement.concat(notNotifyAnnouncement)));
            dispatch(areThereNotify({ chats: allChatsNotify, idOwner: tkn.id }));
        }
    }

    useEffect(() => {
        if (token) {
            dispatch(getSingleUserFunc({ id: tkn.id, token: token }));
            dispatch(getAllChatsNotifyByIdOwnerUserFunc({ idOwnerUser: tkn.id, token: token }))
                .then((res) => {
                    if (res.payload.statusCode === 200 && res.payload.data.length > 0) {
                        dispatch(areThereNotify({ chats: res.payload.data, idOwner: tkn.id }))
                    }
                })
            dispatch(getAllAnnouncementsByIdOwnerFunc({ idOwner: tkn.id, token: token }))
                .then((res) => {
                    if (res.payload.statusCode === 408) {//jwtVerify
                        dispatch(setIsLogged(false));
                        navigate('/login');
                        localStorage.clear()
                    }
                });
            dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
                .then((res) => {
                    if (res.payload.statusCode !== 408 && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {
                        dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token: token }));
                    }
                })
        } else {
            dispatch(setIsLogged(false));
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        if (myChatState) {
            setIsFavouritesChat(myChatState.isFavouriteChat);
            setIdChat(myChatState.idChat);
            setOpenChat(myChatState.openChat)
        }
    }, [myChatState]);

    useEffect(() => {
        chatRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    useEffect(() => {
        if (token) {
            announcementsChatOrganizer()
        }
    }, [allChatsNotify, allUserAnnouncements]);

    useEffect(() => {
        token && favourtitesChatOrganizer()
    }, [allChatsNotify, favouritesData, favoritesAnnouncementsId]);

    useEffect(() => {
        if (favouritesDataNotify.length && !favoritesAnnouncementsId.length) {
            dispatch(setFavoritesAnnouncementsId(favouritesDataNotify.map((el) => { return el.id })));
        }
    }, [favouritesDataNotify])


    return (
        <div ref={chatRef}>

            {
                openChat ?
                    null
                    :
                    <div className='d-flex justify-content-center gap-3 mb-3 pt-4'>
                        <span className={`position-relative ${isFavouritesChat ? "myBgFucsiaRed" : "bg-secondary"} fw-bold text-light myCursor p-1 px-3 rounded-5`} onClick={() => setIsFavouritesChat(true)}><i className="bi bi-heart-fill me-1"></i>
                            Favourites
                            <div className='notifyGeneralChat position-absolute d-flex'>
                                {notifyCountFavourites ? <div className='notifyDotChat d-flex align-items-center justify-content-center'>{notifyCountFavourites > 99 ? 99 : notifyCountFavourites}</div> : null}
                            </div>
                        </span>
                        <span className={`position-relative ${!isFavouritesChat ? "myBgYellow text-dark" : "bg-secondary text-light"} fw-bold myCursor p-1 px-3 rounded-5`} onClick={() => setIsFavouritesChat(false)}><i className="bi bi-house-fill me-1"></i>
                            My Products
                            <div className='notifyGeneralChat position-absolute d-flex'>
                                {notifyCountMyProducts ? <div className='notifyDotChat d-flex align-items-center justify-content-center'>{notifyCountMyProducts > 99 ? 99 : notifyCountMyProducts}</div> : null}
                                {allNewLikesCount ? <div className='notifyDotLikes d-flex align-items-center justify-content-center'>{allNewLikesCount > 99 ? 99 : allNewLikesCount}</div> : null}
                            </div>
                        </span>
                    </div>
            }

            {
                isFavouritesChat ?
                    !favouritesData.length ? isLoading ? <div className='d-flex justify-content-center mt-5'><Spinner animation="border" /></div> : <h1 className='mt-5 fw-light text-center text-secondary'>Chat is empty</h1> : null
                    : !allUserAnnouncementsNotifyOrder.length ? isLoading ? <div className='d-flex justify-content-center mt-5'><Spinner animation="border" /></div> : <h1 className='mt-5 fw-light text-center text-secondary'>Chat is empty</h1> : null
            }

            {
                width < 1100 ? //CHAT MOBILE VERSION
                    <div className='d-flex text-light'>
                        {
                            isFavouritesChat ?
                                <div className='d-flex align-items-center flex-column w-100 pb-5 mb-3'>
                                    {
                                        favouritesDataNotify && favouritesDataNotify.map((el, index) => {//FAVOURITE CHAT
                                            if (openChat && el.id === idChat) {
                                                return <div key={`account3.1-${index}`} className=' position-relative bg-light d-flex justify-content-center' >
                                                    <i className="bi bi-chevron-left position-fixed start-0 ms-2 mt-4 pt-2 mx-1 myCursor display-6 myZindex5" onClick={() => { setOpenChat(false); setIdChat(null); dispatch(clearUsersById()); dispatch(goToMyChat(false)) }}></i>
                                                    <ChatAnnouncement outletUserData={outletUserData} singleData={el} isLoading={isLoading} idOwn={tkn.id} width={width} />
                                                </div>
                                            } if (!openChat) {
                                                return <div key={`account3.2-${index}`} className='w-100 d-flex justify-content-center' onClick={() => { setOpenChat(true); setIdChat(el.id); dispatch(clearUsersById()); dispatch(goToMyChat({ idChat: el.id, typeSubMenu: 2, isFavouriteChat: true, openChat: true })) }}>
                                                    <CardChatAnnouncement outletUserData={outletUserData} idOwn={tkn.id} singleData={el} isLoading={isLoading} notify={el.notify} newMssgCount={el.newMssgCount} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                                :
                                <div className='d-flex align-items-center flex-column w-100 pb-5 mb-3'>
                                    {
                                        allUserAnnouncementsNotifyOrder && allUserAnnouncementsNotifyOrder.map((el, index) => {//ANNOUNCEMENT CHAT
                                            if (openChat && el.id === idChat) {
                                                return <div key={`account4.1-${index}`} className='position-relative bg-light d-flex justify-content-center' >
                                                    {!disableBackArrowChat && <i className="bi bi-chevron-left position-fixed start-0 ms-2 mt-4 pt-2 mx-1 myCursor display-6 myZindex5" onClick={() => { setOpenChat(false); setIdChat(null); dispatch(clearUsersById()); dispatch(goToMyChat(false)) }}></i>}
                                                    <ChatAnnouncement outletUserData={outletUserData} singleData={el} isLoading={isLoading} idOwn={tkn.id} width={width} />
                                                </div>
                                            } if (!openChat) {
                                                return <div key={`account4.2-${index}`} className='w-100 d-flex justify-content-center' onClick={() => { setOpenChat(true); setIdChat(el.id); dispatch(clearUsersById()); dispatch(goToMyChat({ idChat: el.id, typeSubMenu: 2, isFavouriteChat: false, openChat: true })) }}>
                                                    <CardChatAnnouncement outletUserData={outletUserData} idOwn={tkn.id} singleData={el} isLoading={isLoading} notify={el.notify} newMssgCount={el.newMssgCount} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>

                        }

                    </div>
                    : null
            }


            {
                width >= 1100 ? //CHAT DESKTOP VERSION
                    <div className='text-light'>
                        {
                            isFavouritesChat ?
                                <>
                                    <div className='d-flex myVhChat'>
                                        <div className='d-flex align-items-center flex-column myOverflowY pb-5 mb-3' style={{ minWidth: "400px", maxWidth: "700px" }}>
                                            {
                                                favouritesDataNotify && favouritesDataNotify.map((el, index) => {//FAVOURITE CHAT

                                                    return <div key={`account5-${index}`} className={`w-100 pt-1 d-flex justify-content-center ${idChat === el.id ? "myBgChatSelected" : ""}`} onClick={() => { setOpenChat(true); setIdChat(el.id); dispatch(clearUsersById()); dispatch(goToMyChat({ idChat: el.id, typeSubMenu: 2, isFavouriteChat: true, openChat: true })) }}>
                                                        <CardChatAnnouncement outletUserData={outletUserData} idOwn={tkn.id} singleData={el} isLoading={isLoading} notify={el.notify} newMssgCount={el.newMssgCount} />
                                                    </div>

                                                })
                                            }
                                        </div>
                                        <div className='w-100' style={{minWidth: "700px"}}>
                                            {
                                                favouritesDataNotify && favouritesDataNotify.map((el, index) => {
                                                    if (openChat && el.id === idChat) {
                                                        return <div key={`account6-${index}`} className=' position-relative bg-light d-flex justify-content-start' >
                                                            <i className="bi bi-chevron-left position-fixed ms-2 mt-4 pt-2 mx-1 myCursor display-6 myZindex5" onClick={() => { setOpenChat(false); setIdChat(null); dispatch(clearUsersById()); dispatch(goToMyChat(false)) }}></i>
                                                            <ChatAnnouncement outletUserData={outletUserData} singleData={el} isLoading={isLoading} idOwn={tkn.id} width={width} />
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='d-flex myVhChat'>
                                        <div className='d-flex align-items-center flex-column myOverflowY pb-5 mb-3' style={{ minWidth: "400px", maxWidth: "700px" }}>
                                            {
                                                allUserAnnouncementsNotifyOrder && allUserAnnouncementsNotifyOrder.map((el, index) => {//ANNOUNCEMENT CHAT

                                                    return <div key={`account7-${index}`} className={`w-100 d-flex justify-content-center ${idChat === el.id ? "myBgChatSelected" : ""}`} onClick={() => { setOpenChat(true); setIdChat(el.id); dispatch(clearUsersById()); dispatch(goToMyChat({ idChat: el.id, typeSubMenu: 2, isFavouriteChat: false, openChat: true })) }}>
                                                        <CardChatAnnouncement outletUserData={outletUserData} idOwn={tkn.id} singleData={el} isLoading={isLoading} notify={el.notify} newMssgCount={el.newMssgCount} />
                                                    </div>

                                                })
                                            }
                                        </div>
                                        <div className='w-100' style={{minWidth: "700px"}}>
                                            {
                                                allUserAnnouncements && allUserAnnouncements.map((el, index) => {
                                                    if (openChat && el.id === idChat) {
                                                        return <div key={`account8-${index}`} className='position-relative bg-light d-flex justify-content-start' >
                                                            {!disableBackArrowChat && <i className="bi bi-chevron-left position-fixed ms-2 mt-4 pt-2 mx-1 myCursor display-6 myZindex5" onClick={() => { setOpenChat(false); setIdChat(null); dispatch(clearUsersById()); dispatch(goToMyChat(false)) }}></i>}
                                                            <ChatAnnouncement outletUserData={outletUserData} singleData={el} isLoading={isLoading} idOwn={tkn.id} width={width} />
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                    : null
            }

        </div>
    )
}

export default _Chat