import React, { useEffect, useState, useRef } from 'react';
import _Navbar from './_Navbar';
import CardAnnouncement from './CardAnnouncement';
import { useDispatch, useSelector } from 'react-redux';
import decodeToken from '../functions/decodeToken';
import { getAnnouncementsByInterestsFunc, getAnnouncementsByIdFunc, updateAnnouncementFunc, setOutletData } from '../states/storeState';
import { getUserOutletFunc, updateUserOutlet, updateOutletUserData } from '../states/outletStore';
import { useNavigate } from 'react-router';
import Lottie from 'lottie-react';
import idontcareAnim from '../assets/Animation/iDontCare.json';
import ilikeitAnim from '../assets/Animation/iLikeIt.json';
import BoxFalling from '../assets/Animation/boxFalling.json';
import SwipeRight from '../assets/Animation/swipeRight.json';
import { setIsLogged } from '../states/loginState';
import myEncryptCode from '../functions/myEncryptCode';

const _Store = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const outletData = useSelector((state) => state.myStore.outletData);
  const outletUserData = useSelector((state) => state.outlet.outletUserData);
  const waitingForAngelina = useSelector((state) => state.myStore.waitingForAngelina);
  const isLoadingGetAnnouncements = useSelector((state) => state.myStore.isLoadingGetAnnouncements);
  const tkn = decodeToken();
  const [counter, setCounter] = useState(0);
  const [error, setError] = useState("");
  const [waitForTimeReset, setWaitForTimeReset] = useState(false);
  const [remainingPercentage, setRemainingPercentage] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [swipeXStartPosition, setSwipeXStartPosition] = useState(0)
  const [width, setWidth] = useState(window.innerWidth);
  const [triggerAnimLike, setTriggerAnimLike] = useState(false);
  const [triggerAnimDontCare, setTriggerAnimDontCare] = useState(false);
  const [tutorial, setTutorial] = useState(false);
  const [tutorialLike, setTutorialLike] = useState(true);
  const [payloadOutletGlobal, setPayloadOutletGlobal] = useState({});
  const outletRef = useRef();

  let myInterests = "";

  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMi slice'
    }
  }

  const manageSwipe = (e) => {
    if ((swipeXStartPosition - e.changedTouches[0].clientX) > 150) {
      iLikeIt()
    }
    if ((swipeXStartPosition - e.changedTouches[0].clientX) < -150) {
      iDontCare()
    }
  };

  const calculateRemainingTime = () => {
    if (outletUserData.length > 0) {
      const seconds = (new Date().getTime() - new Date(outletUserData[0].resetOutletTime).getTime()) / 1000;
      const myPercentage = Math.floor((seconds / 28800) * 100);
      const myRemainingTime = Math.round((28800 - seconds) / 36) / 100
      setRemainingPercentage(myPercentage);
      setRemainingTime(myRemainingTime);
    }
  };

  const asyncManagement = () => {

    dispatch(setOutletData([...outletData].slice(1)))
    dispatch(updateOutletUserData([payloadOutletGlobal]))
    if (!outletData.length) { calculateRemainingTime(); setWaitForTimeReset(true) }

  };

  useEffect(() => {
    if (triggerAnimDontCare || triggerAnimLike) {
      setTimeout(() => {
        asyncManagement();
        setTriggerAnimDontCare(false);
        setTriggerAnimLike(false)
      }, 800)
    }
  }, [triggerAnimDontCare, triggerAnimLike]);

  const iDontCare = async () => {

    if (!outletUserData[0].resetOutletTime) {

      const myOutletData = outletData[counter];
      const payload = { ...myOutletData, views: outletData[counter].views + 1, negClick: outletData[counter].negClick + 1 };

      dispatch(updateAnnouncementFunc({ payload: payload, token: token }))
        .then((res) => {
          if (res.payload.statusCode === 200) {
          }
        });

      const myOutletUserData = outletUserData[0];
      const newOutletData = [];
      outletData.map((el) => {
        if (el.id !== outletData[counter].id) {
          newOutletData.push(el)
        }
      });
      const myNewOutletSetArray = `${newOutletData.map((el) => { return el.id })}`;
      //per pulire gli array da valori vuoti expl.[1,4,,3]
      let cleanHistoryArray = [];
      let cleanLikesArray = [];
      [...myOutletUserData.outletHistory.split(",")].map((el) => { if (+el !== outletData[counter].id && el !== "" && el !== undefined && el !== "undefined") { return cleanHistoryArray.push(el) } });
      [...myOutletUserData.outletLikes.split(",")].map((el) => { if (+el !== outletData[counter].id && el !== "" && el !== undefined && el !== "undefined") { return cleanLikesArray.push(el) } });

      const payloadOutlet = {
        ...myOutletUserData,
        idOwner: tkn.id,
        outletHistory: myOutletUserData.outletHistory.length > 0 ? [...cleanHistoryArray, outletData[counter].id].toString() : [outletData[counter].id].toString(),
        outletSet: myNewOutletSetArray.toString()
      }

      if (outletData.length === 1) { //se è l'ultimo elemento
        const myPayloadJson = { ...payloadOutlet, resetOutletTime: new Date() }
        setPayloadOutletGlobal(myPayloadJson);
        dispatch(updateUserOutlet({ payload: myPayloadJson, code: await myEncryptCode(myPayloadJson), token: token }))

      } else {//non è l'ultimo elemento
        setPayloadOutletGlobal(payloadOutlet);
        dispatch(updateUserOutlet({ payload: payloadOutlet, code: await myEncryptCode(payloadOutlet), token: token }));
      }

      setTriggerAnimDontCare(true)

    }
  };

  const iLikeIt = async () => {

    if (!outletUserData[0].resetOutletTime) {

      if (tkn.id) {

        const myOutletData = outletData[counter];
        const cleanLikesIds = [];
        myOutletData.likesArry && [...myOutletData.likesArry.split(",")].map((el) => { if (+el !== tkn.id && el !== "" && el !== undefined && el !== "undefined") { return cleanLikesIds.push(el) } });
        const newLikes = myOutletData.newLikes && myOutletData.newLikes !== "null" ? myOutletData.newLikes.split(",").includes(tkn.id.toString()) ? [...myOutletData.newLikes.split(",")] : [...myOutletData.newLikes.split(","), tkn.id] : tkn.id
        const payload = { ...myOutletData, views: outletData[counter].views + 1, posClick: outletData[counter].posClick + 1, likesArry: [...cleanLikesIds, tkn.id], newLikes: newLikes }

        dispatch(updateAnnouncementFunc({ payload: payload, token: token }))
          .then((res) => {
            if (res.payload.statusCode === 200) {


            }
          });

        const myOutletUserData = outletUserData[0];
        const newOutletData = []
        outletData.map((el) => {
          if (el.id !== outletData[counter].id) {
            newOutletData.push(el)
          }
        });
        const myNewOutletSetArray = `${newOutletData.map((el) => { return el.id })}`;
        //per pulire gli array da valori vuoti expl.[1,4,,3]
        let cleanHistoryArray = [];
        let cleanLikesArray = [];
        [...myOutletUserData.outletHistory.split(",")].map((el) => { if (+el !== outletData[counter].id && el !== "" && el !== undefined && el !== "undefined") { return cleanHistoryArray.push(el) } });
        [...myOutletUserData.outletLikes.split(",")].map((el) => { if (+el !== outletData[counter].id && el !== "" && el !== undefined && el !== "undefined") { return cleanLikesArray.push(el) } });

        const payloadOutlet = {
          ...myOutletUserData,
          idOwner: tkn.id,
          outletLikes: myOutletUserData.outletLikes.length > 0 ? [...cleanLikesArray, outletData[counter].id].toString() : [outletData[counter].id].toString(),
          outletHistory: myOutletUserData.outletHistory.length > 0 ? [...cleanHistoryArray, outletData[counter].id].toString() : [outletData[counter].id].toString(),
          outletSet: myNewOutletSetArray.toString()
        }

        if (outletData.length === 1) { //se è l'ultimo elemento
          setPayloadOutletGlobal({ ...payloadOutlet, resetOutletTime: new Date() });
          dispatch(updateUserOutlet({ payload: { ...payloadOutlet, resetOutletTime: new Date() }, token: token }))

        } else {//non è l'ultimo elemento
          setPayloadOutletGlobal(payloadOutlet);
          dispatch(updateUserOutlet({ payload: payloadOutlet, token: token }));
        }

        setTriggerAnimLike(true)

      } else {
        navigate('/login')
      }

    }
  };

  useEffect(() => {//step 0

    if (!token) {
      dispatch(setIsLogged(false));
      navigate('/login');
    }
  }, []);

  useEffect(() => { // step 1

    if (token) {
      tkn.id && dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
        .then((res) => { if (!res.payload.data.length === 0) { navigate("/") } })
    }
  }, []);

  useEffect(() => {// step 2
    if (outletUserData.length !== 0) {
      myInterests = tkn.interests;

      if (!outletUserData[0].outletSet && !outletUserData[0].resetOutletTime) {//#2 se l'outlet set di questo utente NON contiene degli id e non è presente il reset time. Interroga Angelina e ricarica l'outletSet

        setCounter(0);
        const myOutletHistory = outletUserData[0].outletHistory ? outletUserData[0].outletHistory : "0";
        const myOwnInterests = myInterests ? myInterests : "0";
        dispatch(getAnnouncementsByInterestsFunc({ idUser: tkn.id, interests: myOwnInterests, outletHistory: myOutletHistory, token: token }))
          .then((res) => {
            if (res.payload.statusCode === 200 && res.payload.data.length !== 0 && outletUserData) {
              const myOutletSetArray = `${res.payload.data.map((el) => { return el.id })}`;
              const myOutletUserData = outletUserData[0];
              dispatch(updateUserOutlet({ payload: { ...myOutletUserData, outletSet: myOutletSetArray }, token: token }))
                .then((res) => { if (res.payload.statusCode === 200) { window.location.reload() } })
            }
          })
      } else if (outletUserData[0].resetOutletTime) {//se è presente il tempo di reset
        setWaitForTimeReset(true);
        const seconds = (new Date().getTime() - new Date(outletUserData[0].resetOutletTime).getTime()) / 1000;
        const myRemainingTime = Math.round((28800 - seconds) / 36) / 100

        if (myRemainingTime <= 0) {//ripristina l'outlet allo scadere del timer
          const myOutletUserData = outletUserData[0];
          dispatch(updateUserOutlet({ payload: { ...myOutletUserData, resetOutletTime: "" }, token: token }))
            .then((res) => { if (res.payload.statusCode === 200) { window.location.reload() } })
        }

      } else {//#2
        if (!outletData.length) {
          dispatch(getAnnouncementsByIdFunc({ idSet: outletUserData[0].outletSet, token: token }))
        }
      }

    }

  }, [outletUserData]);

  useEffect(() => {//set time left
    calculateRemainingTime()
  }, [waitForTimeReset]);

  useEffect(() => {//switch tra tutoria like e tutorial trash
    setTimeout(() => {
      setTutorialLike(!tutorialLike)
    }, 3400)
  }, [tutorialLike]);

  useEffect(() => {
    
      if (outletUserData.length && !outletUserData[0].outletHistory) {
        setTutorial(true);
      }else{
        setTutorial(false)
      }
    
  }, [outletUserData]);

  useEffect(() => {
    outletRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
}, []);


  return (
    <>
      {
        waitingForAngelina ?
          <div className='w-100' style={{ position: "fixed", height: "100%", zIndex: "103", backgroundColor: "rgb(35,35,35,.8)" }}>
            <div style={{ minHeight: "calc(100vh - 65px)" }} className='position-relative py-5 px-3 d-flex justify-content-start flex-column'>
              <div className="position-relative w-100 myMaxW500" style={{ left: "50%", transform: "translate(-50%, 50%)" }}>
                <Lottie animationData={BoxFalling} options={defaultOptions} />
              </div>
            </div>
          </div>
          : null
      }
      {
        triggerAnimDontCare ?
          <div className='position-relative myZindex5'>
            <div className="position-fixed d-flex justify-content-center w-100" style={{ height: "100vh", background: "rgb(30, 30, 30, .9" }}>
              <Lottie className='myMaxW200 me-3' animationData={idontcareAnim} options={defaultOptions} style={{ left: "-400px" }} />
            </div>
          </div>
          : null
      }
      {
        triggerAnimLike ?
          <div className='position-relative myZindex5'>
            <div className="position-fixed d-flex justify-content-center w-100" style={{ height: "100vh", background: "rgb(30, 30, 30, .9" }}>
              <Lottie className='myMaxW300' animationData={ilikeitAnim} options={defaultOptions} style={{ left: "-400px" }} />
            </div>
          </div>
          : null
      }

      <div className='myMinVh100 d-flex flex-column myBgAbsBlack align-items-center' ref={outletRef} style={{ paddingBottom: `${width > 1000 ? "180px" : "70px"}` }} >
        {
          outletData && width > 1000 ?
            <div className='position-fixed w-100 d-flex justify-content-center align-items-center py-1' style={{ bottom: "55px", zIndex: "2", backgroundColor: "rgb(10,10,10,.8)" }}>
              <div className='w-100 d-flex justify-content-between myMaxW700 pb-2 pt-1'>
                <i className={`bi bi-trash3-fill myIconLg text-light myCursor ms-5 ${tutorial ? "jumpingAnimation" : ""}`} onClick={() => iDontCare()}></i>
                <i className={`bi bi-heart-fill myIconLg myFucsiaRed myCursor me-5  ${tutorial ? "pulsingAnimation" : ""}`} onClick={() => iLikeIt()}></i>
              </div>
            </div>
            : null
        }
        {error ? <div className='mb-3 bg-danger text-light p-3 px-4'>{error}</div> : null}
        <div className='d-flex justify-content-center my-2'>
          {
            outletData && outletData.map((el, index) => {
              return <div key={`store${index}`} className={`border border-dark px-1 ${index === counter ? "myBgYellow" : index < counter ? "bg-dark" : "bg-secondary"} rounded-5`} style={{ height: "10px", margin: "0 1px" }} ></div>
            })
          }
        </div>
        {
          waitForTimeReset ?
            <div className='w-100 px-2 pt-5 text-center text-light'>
              <h3 className='text-center fw-light'>Time left to the next outlet</h3>
              <div className='p-4 pb-4 d-flex justify-content-center align-items-center'>
                <div className='myBgDarkGray rounded-5 mx-3 myMaxW1000 w-100'>
                  <div className='myBgYellow rounded-5 position-relative' style={{ height: "10px", width: `${remainingPercentage <= 100 ? remainingPercentage : "100"}%` }}>
                    <div className={`h-100 ${remainingPercentage > 5 ? "percentageBarGlow" : ""} top-0 position-absolute`} style={{ width: "5vw", zIndex: 9 }}></div>
                  </div>
                </div>
                <h5 className='noBreak'>{remainingPercentage <= 100 ? remainingPercentage : "100"}%</h5>
              </div>
              <h5 >Time left: <b>
                {remainingTime < 1 ? null : `${Math.floor(remainingTime < 0 ? 0 : remainingTime)} hours`} {Math.floor((remainingTime < 0 ? 0 : remainingTime) * 60) % 60} minutes
              </b></h5>
            </div>
            :
            <div className='position-relative'  onTouchStart={(e) => { setSwipeXStartPosition(e.changedTouches[0].clientX) }} onTouchEnd={(e) => { manageSwipe(e) }}>
              <CardAnnouncement singleData={[outletData[counter]]} isLoading={isLoadingGetAnnouncements} />
              {
                tutorial && width <= 1000 &&
                <div className='myCursor' onTouchStart={(e) => { setSwipeXStartPosition(e.changedTouches[0].clientX) }} onTouchEnd={(e) => { manageSwipe(e) }}>
                  {
                    tutorialLike ?
                      <div className='position-absolute h-100 w-100 top-0 myBgTransparentSpecial' >
                        <div className='position-absolute top-0 p-5 w-100 d-flex justify-content-center'><i className='bi bi-heart-fill myFucsiaRed myFontSizeTutorial'></i></div>
                        <Lottie className='top-0' animationData={SwipeRight} options={defaultOptions} />
                      </div>
                      :
                      <div className='position-absolute h-100 w-100 top-0 myBgTransparentSpecial' style={{ transform: "rotateY(180deg)", overflowX: "hidden" }}>
                        <div className='position-absolute top-0 w-100 p-5 d-flex justify-content-center'><i className='bi bi-trash-fill text-light myFontSizeTutorial'></i></div>
                        <Lottie className='top-0' animationData={SwipeRight} options={defaultOptions} />
                      </div>
                  }
                </div>
              }
            </div>
        }

      </div>
    </>
  )
}

export default _Store