import { React, useEffect, useState } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserOutlet } from '../states/outletStore';
import { getFavouritesAnnouncementsByIdFunc } from '../states/storeState';


const CardChatAnnouncement = ({ idOwn, singleData, outletUserData, isLoading, notify, newMssgCount }) => {
    const cardBody = {
        borderBottom: "1px solid #444"
    }
    const dispatch = useDispatch();
    const [isMyAnnouncement, setIsMyAnnouncement] = useState(idOwn === singleData.idOwner ? true : false)



    useEffect(() => {
        
        if (notify && !isMyAnnouncement) {
            const originalArray = outletUserData[0].outletLikes;
            let newArray = [];
            originalArray.split(",").map((el) => {
                if (el != singleData.id) {
                    newArray.push(el)
                }
            });
            newArray.push(singleData.id);
            dispatch(updateUserOutlet({ payload: { id: outletUserData[0].id, outletLikes: newArray }, token: localStorage.getItem("token") }))
                .then((res) => {
                    if (res.payload.statusCode === 200) { dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: newArray.join(), token: localStorage.getItem("token") })); }
                })
        }

    }, [notify])




    return (

        <div className={`px-3 w-100 d-flex align-items-center justify-content-between gap-3 p-1 myCursor`} style={cardBody}>

            <div className='d-flex gap-3'>
                <div className='d-flex align-items-center'>
                    {
                        !singleData || isLoading ?
                            <Placeholder className="imgChatMain" animation="glow"><Placeholder xs={12} style={{ height: "60px", borderRadius: "30px" }} /></Placeholder>
                            :
                            <div className={`myBgImgCover2 imgChatMain myCursor rounded-5 ${notify ? "myImgActiveRing" : ""}`}
                                style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[0]})` }}
                            ></div>

                    }
                </div>
                <div className='d-flex align-items-center'>
                    <div>
                        <div className="line-clamp1 py-2 pe-5" ><h4 className='fw-light'>{singleData.modelName}</h4></div>
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column align-items-end'>
                {notify ? <div className='d-flex align-items-center gap-1 myChatColor noBreak'>{newMssgCount ? newMssgCount : null}<i className="bi bi-chat-dots-fill" ></i></div> : null}
                {isMyAnnouncement && singleData.newLikes && singleData.newLikes !== "null" ? <div className='d-flex align-items-center gap-1 myFucsiaRed'>{singleData.newLikes.split(",").length}<i className="bi bi-heart-fill" ></i></div> : null}
            </div>
        </div>


    )
}

export default CardChatAnnouncement