import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    allCities: [],
    isLoading: false,
    allCitiesFiltered: []
}

export const getCitiesFunc = createAsyncThunk(
    'api/getCities',
    async (input) => {
        const {country, token} = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/geonames/${country}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }
    }
)

const geonamesSlice = createSlice({
    name: 'geonameStore',
    initialState,
    reducers: {
        searchCity: (state, action) => {
            const input = action.payload.toLowerCase();
            const allCities = state.allCities.filter((el) => el.name.toLowerCase().includes(input, 0));
            /* let firstFilter = allCities.sort((a, b) => { return ((a.name.length - input.length) < (b.name.length - input.length)) ? -1 : 0 }); */
            /* let firstFilter = allCities.sort((a, b) => { return ((a.name.length - input.length) < (b.name.length - input.length)) ? -1 : 0 }); */
            const secondFilter = allCities.sort((a, b) => { return ((a.population) < (b.population)) ? -1 : 0 });
            state.allCitiesFiltered = [...secondFilter].reverse();
        },
        clearSearchCity: (state, action) => {
            state.allCitiesFiltered = []
        }
    },

    extraReducers: (builder) => {
        //detCities
        builder.addCase(getCitiesFunc.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getCitiesFunc.fulfilled, (state, action) => {
            state.allCities = action.payload.payload.sort((a, b) => { return a.name.localeCompare(b.name) });
            state.isLoading = false
        })
        builder.addCase(getCitiesFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = " server error"
        });
    }
});

export const { searchCity, clearSearchCity } = geonamesSlice.actions;
export default geonamesSlice.reducer
