import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import "../styles/accountCardBody.css";
import notificationAssigner from '../functions/notificationAssigner';
import { useDispatch, useSelector } from 'react-redux';
import chatState, { goToMyChat } from '../states/chatState';
import { useRef } from 'react';
import { useNavigate } from 'react-router';

const CardPenRejAnnouncementGrid = ({ idOwn, singleData }) => {

    const [notify, setNotify] = useState(false);
    const [renderHeight, setRenderHeight] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const targetRef = useRef();
    //chat
    const allChatsNotify = useSelector((state) => state.chat.allChatsNotify);
    const notifyCount = useSelector((state) => state.chat.notifyCount);

    const imgStyle = {
        width: "150px",
        marginBottom: "10px",
        gridRowEnd: `span ${renderHeight + 12}`
    }


    useEffect(() => {
        if (notificationAssigner({ allChatsNotify, idOwn, singleData })) {
            setNotify(true)
        }
    }, [allChatsNotify, notifyCount]);

    useEffect(() => {
        setRenderHeight(targetRef.current.clientHeight);
    }, [])



    return (
        <>
            {
                renderHeight ?
                    <div className={`position-relative rounded-5 shadow border border-dark myCursor`} style={imgStyle} onClick={() => { dispatch(goToMyChat({ idChat: singleData.id, typeSubMenu: 2, isFavouriteChat: true, openChat: true })); navigate('/chat') }}>
                        <div className={`overlayVerticalGrid rounded-5 d-flex gap-2 justify-content-center align-items-end pb-2 myFontSize07Em ${notify ? "myImgActiveRing":""}`}>
                            <i className="bi bi-eye-fill text-light "> {singleData.views}</i>
                            <i className="bi bi-suit-heart-fill myFucsiaRed" > {singleData.posClick}</i>
                            <i className={`myZindex2 bi bi-chat-dots-fill ${notify ? "myChatColor" : "text-light"} myCursor`} ></i>
                        </div>
                        <img className="w-100 rounded-5" ref={targetRef} src={`${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[0]}`} alt="" />
                    </div>
                    :
                    <div className='position-relative rounded-5 shadow' style={imgStyle}>
                        <img className='w-100 rounded-5' ref={targetRef} onLoad={(e) => { setRenderHeight(e.target.clientHeight) }} src={`${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[0]}`} alt="" />
                    </div>
            }
        </>
    )
}

export default CardPenRejAnnouncementGrid